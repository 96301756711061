.index-module_channelcard__8TZbT {
  width: 18rem;
  height: 18rem;
  box-sizing: border-box;
  padding-right: 1rem;
  padding-left: 1rem;
  border: 0.1rem solid #d9d9d9;
  background-color: #fff;
  border-radius: 0.6rem;
  text-align: center;
}

.index-module_channelcard--disabled__tsRIS {
  cursor: not-allowed;
  opacity: 0.66;
}

.index-module_channelcard__icon__4MStn {
  display: block;
  width: 6rem;
  height: 6rem;
  margin-top: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.index-module_channelcard__title__3pCy6 {
  height: 4.3rem;
  margin: 0.8rem 0 0;
  color: #434b65;
  font-family: "OpenSans", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
}

.index-module_channelcard__button__FdENh {
  display: block;
  justify-content: center;
  padding: 0.9rem 1.6rem;
  border: none;
  margin-right: auto;
  margin-left: auto;
  background-color: #3b86ff;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  opacity: 0.75;
  outline: 0;
  text-transform: uppercase;
}

.index-module_channelcard__button__FdENh:hover {
  opacity: 1;
}

.index-module_channelcard__text__BlKQF {
  display: flex;
  justify-content: center;
  padding: 1.1rem 1.6rem;
  margin-top: 1.2rem;
  color: hsl(220, 21%, 37%);
  font-family: "OpenSans", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.index-module_channelcard__status-indicator__JmgIE {
  width: 0.688rem;
  height: 0.688rem;
  margin: 0.313rem 0.438rem 0.375rem 0;
  background-color: hsl(151, 100%, 50%);
  border-radius: 50%;
}
