.index-module_connected__instagram-informations__HN6UY {
  margin: 0 0 var(--spacing-m) 0;
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.38;
}

.index-module_connected__instagram-informations__HN6UY:last-child {
  margin-bottom: var(--spacing-xxl-3);
}

.index-module_connected__describe__t7gDj {
  margin: 0;
}

.index-module_connected__desribe-link__YrGrd {
  color: var(--dark);
}

.index-module_connected__desribe-link__YrGrd:hover {
  color: var(--blue-500);
}

.index-module_connected__informations__PDmDk {
  display: flex;
  flex-direction: column;
}

.index-module_connected__status__lhb4v {
  margin-bottom: var(--spacing-xl);
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.38;
}

.index-module_connected__instagram-informations__IJ0-I {
  margin-top: 0;
  margin-bottom: var(--spacing-xxl-3);
}

.index-module_connected__buttons-container__tM-ZN {
  display: flex;
}

.index-module_connected__update-button__M8Hu8 {
  margin-right: var(--spacing-m);
}

svg.index-module_connected__instagram-login-button-icon__8FmSD g path {
  fill: var(--light);
}

.index-module_connected__describe-with-link__eZbAJ {
  display: flex;
}

.index-module_connected__visit__VH6PP {
  margin-right: var(--spacing-xxs);
}

.index-module_disconnected__describe__SlqT9 {
  margin: 0;
}

.index-module_disconnected__describe-with-link__ePgiW {
  display: flex;
}

.index-module_disconnected__visit__Adksr {
  margin-right: var(--spacing-xxs);
}

.index-module_disconnected__instagram-login-button__BdHVx {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: linear-gradient(45deg, rgba(255, 220, 128, 1), rgba(252, 175, 69, 1), rgba(247, 119, 55, 1), rgba(245, 96, 64, 1), rgba(253, 29, 29, 1), rgba(225, 48, 108, 1), rgba(193, 53, 132, 1), rgba(131, 58, 180, 1), rgba(88, 81, 219, 1), rgba(64, 93, 230, 1)) !important;
}

.index-module_disconnected__instagram-login-button__BdHVx:hover {
  background: linear-gradient(45deg, rgba(255, 220, 128, 0.9), rgba(252, 175, 69, 0.9), rgba(247, 119, 55, 0.9), rgba(245, 96, 64, 0.9), rgba(253, 29, 29, 0.9), rgba(225, 48, 108, 0.9), rgba(193, 53, 132, 0.9), rgba(131, 58, 180, 0.9), rgba(88, 81, 219, 0.9), rgba(64, 93, 230, 0.9)) !important;
}

svg.index-module_disconnected__instagram-login-button-icon__QgjOl g path {
  fill: var(--light);
}

.index-module_loading__DREhc {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

svg.index-module_loading__icon__glAbt {
  width: var(--spacing-xxl-2);
  height: var(--spacing-xxl-2);
}

.index-module_connected__messenger-informations__YSGBR {
  margin: 0 0 var(--spacing-m) 0;
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.38;
}

.index-module_connected__messenger-informations__YSGBR:last-child {
  margin-bottom: var(--spacing-xxl-3);
}

.index-module_connected__describe__lTuiM {
  margin: 0;
}

.index-module_connected__desribe-link__3UP4e {
  color: var(--dark);
}

.index-module_connected__desribe-link__3UP4e:hover {
  color: var(--blue-500);
}

.index-module_connected__informations__xcZrG {
  display: flex;
  flex-direction: column;
}

.index-module_connected__status__n2Kui {
  margin-bottom: var(--spacing-xl);
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.38;
}

.index-module_connected__messenger-informations__Xx9so {
  margin-top: 0;
  margin-bottom: var(--spacing-xxl-3);
}

.index-module_connected__buttons-container__ePbzH {
  display: flex;
}

.index-module_connected__update-button__aAW8s {
  margin-right: var(--spacing-m);
}

svg.index-module_connected__messenger-login-button-icon__StLbh g path {
  fill: var(--light);
}

.index-module_connected__describe-with-link__euqn2 {
  display: flex;
}

.index-module_connected__visit__DCNxs {
  margin-right: var(--spacing-xxs);
}

.index-module_disconnected__describe__esa13 {
  margin: 0;
}

svg.index-module_disconnected__messenger-login-button-icon__bA8zU g path {
  fill: var(--light);
}

.index-module_disconnected__describe-with-link__jaKOq {
  display: flex;
}

.index-module_disconnected__messenger-login-button__tNov2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.index-module_disconnected__visit__UWn9i {
  margin-right: var(--spacing-xxs);
}

.index-module_loading__ILQTK {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

svg.index-module_loading__icon__R2e-X {
  width: var(--spacing-xxl-2);
  height: var(--spacing-xxl-2);
}

.index-module_connected__describe__FgcAw,
.index-module_connected__footer__jOxYy {
  margin: 0;
}

.index-module_connected__informations__TNye- {
  margin-bottom: 2.5rem;
}

.index-module_connected__status__CKrM6 {
  font-weight: 500;
}

.index-module_connected__informations__TNye- > .index-module_connected__shop-name__n7cCb {
  margin-bottom: var(--spacing-xl);
}

.index-module_connected__informations__TNye- p {
  margin: 0;
}

.index-module_connected__steps__UNffo {
  margin: 0;
  margin-bottom: 2.5rem;
  -webkit-padding-start: 50px;
          padding-inline-start: 50px;
}

.index-module_connected__steps__UNffo li {
  padding-left: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.index-module_connected__steps__UNffo li::marker {
  width: 2rem;
  font-weight: 500;
}

.index-module_connected__steps__UNffo li:last-child {
  margin-bottom: 0;
}

.index-module_step__one__-eSBZ {
  list-style-type: 'Step 1';
}

.index-module_step__two__GCWi- {
  list-style-type: 'Step 2';
}

.index-module_step__three__rbPEk {
  list-style-type: 'Step 3';
}

.index-module_connected__steps__UNffo li p:first-child {
  font-weight: 500;
}

.index-module_connected__footer__jOxYy {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-xl);
}

.index-module_connected__shopify-logout-button__QwCs2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--shopify-color-500);
}

.index-module_connected__shopify-logout-button__QwCs2:hover {
  background-color: var(--shopify-color-300);
}

.index-module_connected__shopify-logout-button__QwCs2:active {
  background-color: var(--shopify-color-700);
}

svg.index-module_connected__shopify-logout-button-icon__7DGsU path {
  fill: var(--light);
}

.index-module_connected__link-step-one__uRi-x,
.index-module_connected__link-step-two__vbO-S,
.index-module_connected__link-step-three__4W9g1 {
  display: flex;
  flex-wrap: wrap;
}

.index-module_connected__content-in-step-one__MzyAM,
.index-module_connected__content-in-step-two__TnqRJ,
.index-module_connected__content-in-step-three__Pu2Kw,
.index-module_connected__content-footer__M1Pv4 {
  margin-right: var(--spacing-xxs);
}

.index-module_disconnected__describe__ofBjv {
  margin: 0;
}

.index-module_disconnected__input-container__ydrsH {
  position: relative;
  width: 20rem;
  padding-left: var(--spacing-m);
  margin-bottom: var(--spacing-m);
}

.index-module_disconnected__input-container__ydrsH::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 4.5rem;
  border-left: 1px var(--gray-500) solid;
  content: '';
}

.index-module_disconnected__shopify-login-button__5QLGq {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--shopify-color-500);
}

.index-module_disconnected__shopify-login-button__5QLGq:hover {
  background-color: var(--shopify-color-300);
}

.index-module_disconnected__shopify-login-button__5QLGq:active {
  background-color: var(--shopify-color-700);
}

svg.index-module_disconnected__shopify-login-button-icon__rHRMZ path {
  fill: var(--light);
}

.index-module_disconnected__describe-with-link__Q2vNC {
  display: flex;
}

.index-module_disconnected__visit__YOnib {
  margin-right: var(--spacing-xxs);
}

.index-module_form__omXAz {
  padding: 1.813rem 11.313rem 1.813rem 2rem;
  border: solid 1px hsl(0, 0%, 91%);
  background-color: #fff;
  border-radius: 8px;
}

.index-module_form__header__bjvvY {
  margin: 0 0 0.625rem 0;
  color: hsl(226, 20%, 33%);
  font-family: 'Open Sans', sans-serif;
  font-size: 1.75rem;
  font-weight: bold;
}

.index-module_form__description__gwXA7 {
  margin: 0.625rem 0 1.625rem;
  color: hsl(0, 0%, 73%);
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.index-module_form__description__gwXA7 a {
  color: hsl(0, 0%, 73%);
}

.index-module_zendesk-form__container__xCrEw {
  height: 4.063rem;
  padding-bottom: 0.563rem;
  padding-left: 1.188rem;
  border-left: solid 2px hsla(0, 0%, 59%, 0.08);
}

.index-module_zendesk-form__label__ogy5s {
  margin: 0 3.563rem 0.563rem 0;
  color: hsl(226, 58%, 25%);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.765rem;
  font-weight: bold;
}

.index-module_zendesk-form__input-container__3m1jk {
  display: flex;
}

.index-module_zendesk-form__input__slx3r {
  padding: 0.625rem 2.125rem 0.5rem 0.75rem;
  border: solid 0.7px hsl(0, 0%, 93%);
  margin: 0.563rem 0.563rem 0 0;
  background-color: #fff;
  border-radius: 6.5px;
}

.index-module_zendesk-form__input__slx3r::placeholder {
  color: hsl(226, 20%, 33%);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.765rem;
  opacity: 0.5;
}

.index-module_zendesk-form__input-domain__L4GH5 {
  margin: 1.063rem 0 0.625rem 0.563rem;
  color: hsl(226, 58%, 25%);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.765rem;
  font-weight: bold;
}

.index-module_zendesk-form__button__JQOFp {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.75rem 0.688rem;
  border: none;
  margin: 1.813rem 0 0 0;
  background-color: hsl(0, 0%, 92%);
  border-radius: 7px;
  cursor: pointer;
}

.index-module_zendesk-form__error-message__ogQSD {
  margin-top: 1rem;
  margin-bottom: 0;
  color: hsl(226, 20%, 33%);
  font-family: 'Open Sans', sans-serif;
}

.index-module_button__logo__KaN-4 {
  width: 1.125rem;
  height: 0.875rem;
  margin-right: 0.5rem;
}

.index-module_button__label__h2m4z {
  color: hsl(185, 100%, 12%);
  font-family: 'Open Sans', sans-serif;
  font-size: 0.765rem;
  font-weight: bold;
}
