.index-module_answers-viewer-accordions__CSc8m {
  display: flex;
  flex-direction: column;
}

.index-module_answers-viewer-accordions__add-scenario__JFItW {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_answers-viewer-accordions__scenario__RcwvE {
  order: -1;
  margin-bottom: var(--spacing-m);
}

.index-module_answers-viewer-accordions__scenario__RcwvE:last-child {
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_answers-viewer-accordion__title__TBDFf,
.index-module_answers-viewer-accordion__element__ZCLyG {
  margin: 0;
}

.index-module_answers-viewer-accordion__title__TBDFf {
  margin-bottom: var(--spacing-xxl-2);
  font-weight: unset;
  text-transform: uppercase;
}

.index-module_answers-viewer-accordion__title--conditions__EuQrC {
  margin-bottom: var(--spacing-m);
}

.index-module_answers-viewer-accordion__conditions__6cEoD {
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_answers-viewer-accordion__actions__Cbkv0 {
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_answers-viewer-accordion__element__ZCLyG {
  margin-bottom: var(--spacing-m);
}

.index-module_answers-viewer-accordion__field-set__h7x2r {
  align-items: center;
}

.index-module_answers-viewer-accordion__statement__9isxs {
  margin-bottom: var(--spacing-xxs);
}

.index-module_answers-viewer-accordion__select-input-value__JiHnb,
.index-module_answers-viewer-accordion__select-operator__eB9m-,
.index-module_answers-viewer-accordion__input-conditionnal-value__6zujw {
  width: 100%;
  max-width: 20rem;
  margin-bottom: var(--spacing-xxs);
}

.index-module_answers-viewer-accordion__content-info__9F7YR {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_answers-viewer-accordion__empty-questions__YrN5G {
  margin: 0 0 var(--spacing-xs) 0;
}

.index-module_answers-viewer-accordion__empty-questions-error__5lBCp {
  font-weight: normal;
}

@media (min-width: 768px) {
  .index-module_answers-viewer-accordion__statement__9isxs {
    width: 2.5rem;
    margin-right: var(--spacing--xxs);
    margin-bottom: 0;
  }

  .index-module_answers-viewer-accordion__field-set__h7x2r {
    flex-direction: column;
    align-items: flex-start;
  }

  .index-module_answers-viewer-accordion__select-input-value__JiHnb,
  .index-module_answers-viewer-accordion__select-operator__eB9m-,
  .index-module_answers-viewer-accordion__input-conditionnal-value__6zujw {
    margin-right: var(--spacing-m);
    margin-bottom: 0;
  }

  .index-module_answers-viewer-accordion__select-input-value__JiHnb {
    width: 12.5rem;
  }

  .index-module_answers-viewer-accordion__select-operator__eB9m- {
    width: 9.375rem;
  }

  .index-module_answers-viewer-accordion__input-conditionnal-value__6zujw {
    width: 10.625rem;
  }
}

.index-module_choice-button__container__K3ccG {
  display: flex;
  width: 100%;
  align-items: center;
}

.index-module_choice-button__inputs__Hxld- {
  width: 100%;
  margin-right: var(--spacing-m);
}

.index-module_choice-button__choice__dspP3,
.index-module_choice-button__select-button-type__cmrjC {
  width: 100%;
  margin-bottom: var(--spacing-xs);
}

.index-module_choice-button__input-for-selected-choice-button-type__wIeeK {
  width: 100%;
}

.index-module_choice-button__delete-container--hidden__QzXJ8 {
  visibility: hidden;
}

@media (min-width: 768px) {
  .index-module_choice-button__choice__dspP3,
  .index-module_choice-button__select-button-type__cmrjC {
    margin-right: var(--spacing-m);
    margin-bottom: 0;
  }

  .index-module_choice-button__choice__dspP3,
  .index-module_choice-button__input-for-selected-choice-button-type__wIeeK {
    max-width: 21.875rem;
  }

  .index-module_choice-button__select-button-type__cmrjC {
    max-width: 10.063rem;
  }

  .index-module_choice-button__inputs__Hxld- {
    display: flex;
    max-width: calc(21.875rem + 21.875rem + 10.063rem + 32px);
  }
}

.index-module_type-choices__layout__iK6Jm,
.index-module_type-choices__question__3atPc,
.index-module_choice__question__N5JNC {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.index-module_type-choices__content__Ii7-- {
  width: 100%;
}

.index-module_type-choices__as__qWq7Q,
.index-module_choice__as__MtVQs {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 0;
  text-align: center;
}

.index-module_type-choices__assignment__fUvkf,
.index-module_choice__assignment__vq0Kl {
  width: 100%;
}

.index-module_type-choices__choices-container__23Fmg {
  display: flex;
  flex-direction: column;
}

.index-module_type-choices__choices-title__1-jEU {
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.index-module_choice__container__eQ9vu {
  display: flex;
  width: 100%;
  align-items: center;
}

.index-module_choice__inputs__PNlyi {
  width: 100%;
  margin-right: var(--spacing-m);
}

@media (min-width: 768px) {
  .index-module_type-choices__inputs__5RcLQ {
    display: flex;
    align-items: flex-end;
  }

  .index-module_choice__inputs__PNlyi {
    display: flex;
    max-width: calc(41.25rem + 12.5rem + 49px);
  }

  .index-module_type-choices__as__qWq7Q,
  .index-module_choice__as__MtVQs {
    padding: 0.5rem var(--spacing-m);
  }

  .index-module_type-choices__assignment__fUvkf,
  .index-module_choice__assignment__vq0Kl {
    max-width: 20rem;
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  .index-module_type-choices__question__3atPc {
    max-width: 43.75rem;
  }

  .index-module_choice__question__N5JNC {
    max-width: 41.25rem;
  }

  .index-module_type-choices__assignment__fUvkf,
  .index-module_choice__assignment__vq0Kl {
    max-width: 12.5rem;
    flex-grow: 1;
  }
}

.index-module_smart-skills-form-title__p-2RL {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_smart-skills-form-title__internal-link__RC82E {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-m);
}

.index-module_smart-skills-form-title__layout-title__8h6j3 {
  margin: 0;
}

.index-module_questions__element__T5wjZ {
  margin: 0;
  margin-bottom: var(--spacing-m);
}

.index-module_questions__drag-and-drop__z49l4 {
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_questions__drag-and-drop--empty__WYSHC {
  margin-bottom: 0;
}

.index-module_questions__content-info__1-57L {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_questions__empty-questions__0meXi {
  margin: 0 0 var(--spacing-xs) 0;
}

.index-module_questions__empty-questions-error__fiamZ {
  font-weight: normal;
}

.index-module_questions__empty-questions-error--hidden__JAYAI {
  visibility: hidden;
}

.index-module_choices-button__question__xmpKi {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.index-module_choices-button__as__AvxBo {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 0;
  text-align: center;
}

.index-module_choices-button__assignment__DyH1a {
  width: 100%;
}

.index-module_choices-button__container__2gMbL {
  display: flex;
  width: 100%;
  align-items: center;
}

.index-module_choices-button__inputs__ipd6V {
  width: 100%;
  margin-right: var(--spacing-m);
}

.index-module_choices-button__delete-container--hidden__GlgyQ {
  visibility: hidden;
}

@media (min-width: 768px) {
  .index-module_choices-button__inputs__ipd6V {
    display: flex;
    max-width: calc(41.25rem + 12.5rem + 49px);
  }

  .index-module_choices-button__as__AvxBo {
    padding: 0.5rem var(--spacing-m);
  }

  .index-module_choices-button__assignment__DyH1a {
    max-width: 20rem;
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  .index-module_choices-button__question__xmpKi {
    max-width: 41.25rem;
  }

  .index-module_choices-button__assignment__DyH1a {
    max-width: 12.5rem;
    flex-grow: 1;
  }
}

.index-module_scenario__tahV3 {
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_scenario__title__iwz7j {
  margin: 0;
  margin-bottom: var(--spacing-xxl-2);
  font-weight: unset;
  text-transform: uppercase;
}

.index-module_scenario__title--conditions__yDHvs {
  margin-bottom: var(--spacing-m);
}

.index-module_scenario__field-set__1uFCJ {
  align-items: center;
}

@media (min-width: 768px) {
  .index-module_scenario__field-set__1uFCJ {
    flex-direction: column;
    align-items: flex-start;
  }
}

.index-module_scenario-condition__-vKxi {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.index-module_scenario-condition__statement__N4Vs6 {
  margin-bottom: var(--spacing-xxs);
  text-transform: uppercase;
}

.index-module_scenario-condition__select-input-value__zkgJl,
.index-module_scenario-condition__select-operator__9C5pC,
.index-module_scenario-condition__input-conditionnal-value__q0AT1 {
  width: 100%;
  max-width: 20rem;
  margin-bottom: var(--spacing-xxs);
}

.index-module_scenario-condition__input-conditionnal-value--hidden__fWKsx {
  visibility: hidden;
}

@media (min-width: 768px) {
  .index-module_scenario-condition__-vKxi {
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--spacing-xs);
  }

  .index-module_scenario-condition__statement__N4Vs6 {
    width: 2.5rem;
    margin-right: var(--spacing-xs);
    margin-bottom: 0;
  }

  .index-module_scenario-condition__select-input-value__zkgJl,
  .index-module_scenario-condition__select-operator__9C5pC,
  .index-module_scenario-condition__input-conditionnal-value__q0AT1 {
    margin-right: var(--spacing-m);
    margin-bottom: 0;
  }

  .index-module_scenario-condition__select-input-value__zkgJl {
    width: 12.5rem;
  }

  .index-module_scenario-condition__select-operator__9C5pC {
    width: 9.375rem;
  }

  .index-module_scenario-condition__input-conditionnal-value__q0AT1 {
    width: 10.625rem;
  }
}


.index-module_smart-skills-form-setup__input-name__iDmhR {
  max-width: 25rem;
}

.index-module_smart-skills-form-setup__7Rnlb {
  margin-bottom: var(--spacing-xxl-2);
}


.index-module_smart-skills-content__snRUt {
  max-width: 72.125rem;
}

.index-module_smart-skills-content__button-container__ZEj-a {
  display: flex;
  justify-content: center;
}

.index-module_smart-skills-content__button__uz8qI {
  max-width: 36.625rem;
}

.index-module_type-api__select__1TrV9 {
  min-width: 10rem;
  max-width: 31.25rem;
  margin-right: 6.25rem;
}

.index-module_type-api__list-item-icon__hzPLW,
.index-module_type-api__list-item__cmTOY > span {
  color: var(--gray-700);
}

.index-module_type-api__lists__ANmNr {
  display: flex;
}

.index-module_type-api__list-empty__h1rnT {
  font-style: italic;
}

.index-module_type-api__inputs-list__ygtcL {
  margin-right: var(--spacing-xl);
}

@media (min-width: 1024px) {
  .index-module_type-api__select__1TrV9 {
    width: 100%;
  }

  .index-module_type-api__content__rindm {
    display: flex;
    width: 100%;
  }
}

.index-module_type-buttons__assistant-answer__Lp-xl {
  width: 100%;
}

.index-module_type-buttons__layout__TQyvu {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.index-module_type-buttons__buttons-container__ir8AF {
  display: flex;
  flex-direction: column;
}

.index-module_type-buttons__buttons-title__Vsvmv {
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.index-module_type-choices__layout__uRwn-,
.index-module_type-choices__question__addHr,
.index-module_choice__question__tJmdQ {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.index-module_type-choices__content__QLYFB {
  width: 100%;
}

.index-module_type-choices__as__XBCPT,
.index-module_choice__as__3nxIp {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 0;
  text-align: center;
}

.index-module_type-choices__assignment__Zb0r9,
.index-module_choice__assignment__Gtbdu {
  width: 100%;
}

.index-module_type-choices__choices-container__-jYNQ {
  display: flex;
  flex-direction: column;
}

.index-module_type-choices__choices-title__s-gmF {
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.index-module_choice__container__b6hzP {
  display: flex;
  width: 100%;
  align-items: center;
}

.index-module_choice__inputs__Xsx7K {
  width: 100%;
  margin-right: var(--spacing-m);
}

@media (min-width: 768px) {
  .index-module_type-choices__inputs__rV4qL {
    display: flex;
    align-items: flex-end;
  }

  .index-module_choice__inputs__Xsx7K {
    display: flex;
    max-width: calc(41.25rem + 12.5rem + 49px);
  }

  .index-module_type-choices__as__XBCPT,
  .index-module_choice__as__3nxIp {
    padding: 0.5rem var(--spacing-m);
  }

  .index-module_type-choices__assignment__Zb0r9,
  .index-module_choice__assignment__Gtbdu {
    max-width: 20rem;
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  .index-module_type-choices__question__addHr {
    max-width: 43.75rem;
  }

  .index-module_choice__question__tJmdQ {
    max-width: 41.25rem;
  }

  .index-module_type-choices__assignment__Zb0r9,
  .index-module_choice__assignment__Gtbdu {
    max-width: 12.5rem;
    flex-grow: 1;
  }
}

.index-module_type-free-input__container__6vMT0 {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.index-module_type-free-input__content__avj6T {
  width: 100%;
}

.index-module_type-free-input__question__tq8Sx {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.index-module_type-free-input__as__QABg4 {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 0;
  text-align: center;
}

.index-module_type-free-input__validation__Ykm4V {
  display: flex;
}

.index-module_type-free-input__select-validation__tLTL4 {
  width: 10.938rem;
  margin-right: var(--spacing-m);
}

.index-module_type-free-input__input-regex__HV52V {
  width: 100%;
}

@media (min-width: 768px) {
  .index-module_type-free-input__inputs__YFV5T {
    display: flex;
    align-items: flex-end;
  }

  .index-module_type-free-input__as__QABg4 {
    padding: 0.5rem var(--spacing-m);
  }

  .index-module_type-free-input__assignment__BKE0L {
    max-width: 20rem;
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  .index-module_type-free-input__question__tq8Sx {
    max-width: 43.75rem;
  }
}

.index-module_type-go-to-skill__select__7wvCV {
  min-width: 10rem;
  max-width: 31.25rem;
  margin-right: 6.25rem;
}

@media (min-width: 1024px) {
  .index-module_type-go-to-skill__select__7wvCV {
    width: 100%;
  }

  .index-module_type-go-to-skill__content__Kzyai {
    display: flex;
    width: 100%;
  }
}

.index-module_answer-type-text__container-input__mEQKS {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.index-module_answer-type-text__input__km1hb {
  width: 100%;
}

.index-module_answer-type-picture__error-message__BLduA {
  visibility: hidden;
}

.index-module_answer-type-picture__error-message--visible__QGX5o {
  visibility: visible;
}

.index-module_answer-type-text__container-input__ePjiF {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.index-module_answer-type-text__input__58Jv4 {
  width: 100%;
}
