.index-module_api-connexion__x8hZ2 {
  overflow-x: auto;
}

.index-module_api-connexion__add-button__g06QN {
  width: 7.813rem;
}

.index-module_api-connexion__settings__PlThF {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
  height: auto;
  min-height: 100%;
}

.index-module_api-connexion__headers__xCGO2 {
  margin-right: var(--spacing-m);
}

.index-module_api-connexion__label-text-area-component__lHftY {
  height: 100%;
}

.index-module_api-connexion__text-area-component__BmgzB {
  width: 100%;
  height: 100%;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.index-module_headers__api-headers__331eO {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--spacing-xs);
}

.index-module_headers__api-headers__331eO div:last-child {
  margin: 0;
}

.index-module_headers__property__Ig2rr,
.index-module_headers__value__TBkSB {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-right: var(--spacing-xs);
}

.index-module_headers__property__Ig2rr {
  width: 12.5rem;
}

.index-module_headers__value__TBkSB {
  width: 18.75rem;
}

.index-module_headers__delete-button--hidden__b8aG4 {
  visibility: hidden;
}

.index-module_api-url-settings__api-url__AJNjk {
  display: flex;
  align-items: flex-end;
}

.index-module_api-url-settings__api-url__AJNjk > div:last-child {
  margin: 0;
}

.index-module_api-url-settings__verb-select__EKg6Z,
.index-module_api-url-settings__url__wUWiv {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-right: var(--spacing-xs);
}

.index-module_api-url-settings__verb-select__EKg6Z {
  max-width: 6.25rem;
}

.index-module_customer-api-form__form__XlffL {
  max-width: 72.125rem;
}

.index-module_customer-api-form__outputs__ueVTd {
  max-width: 72.125rem;
}

.index-module_customer-api-form__save-button__bqNNi {
  width: 50%;
  margin: 0 auto;
  margin-bottom: var(--spacing-xxl-3);
}

.index-module_customer-apis-form-title__IBhTb {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xxl-2);
}

.index-module_customer-apis-form-title__internal-link__yjoyQ {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-m);
}

.index-module_customer-apis-form-title__layout-title__8yZ7H {
  margin: 0;
}

.index-module_inputs__add-button__Cs8aI {
  width: 7.813rem;
}

.index-module_input-line__inputs__2Ke99 {
  display: flex;
  margin-bottom: var(--spacing-xs);
}

.index-module_input-line__name__EqlMd,
.index-module_input-line__type__sd-ZV,
.index-module_input-line__value__IJSMV {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-right: var(--spacing-m);
}

.index-module_input-line__name__EqlMd {
  max-width: 15.625rem;
}

.index-module_input-line__type__sd-ZV {
  max-width: 18.75rem;
}

.index-module_input-line__value__IJSMV {
  max-width: 12.5rem;
}

.index-module_input-line__inputs__2Ke99 div:last-child {
  margin: 0;
}

.index-module_input-line__delete-button--hidden__LKvcF {
  visibility: hidden;
}

.index-module_outputs__RoU4- {
  overflow-x: auto;
}

.index-module_outputs__content__NhMPs {
  display: flex;
}

.index-module_outputs__return__nRjZN {
  margin-right: var(--spacing-xxl-2);
}

.index-module_outputs__text-area-component__jJNLS {
  width: calc(344px - 16px - 16px);
  height: 150px;
}

.index-module_outputs__line-output__474fU {
  display: flex;
  margin-bottom: var(--spacing-xs);
}

.index-module_outputs__add-button__pG5yx {
  width: 7.813rem;
}

.index-module_outputs-line__6uNAY {
  display: flex;
  margin-bottom: var(--spacing-xs);
}

.index-module_outputs-line__output-name__Q34li,
.index-module_outputs-line__output-value__ztBs6,
.index-module_outputs-line__output-type__5l3Sx {
  display: flex;
  flex-direction: column;
  margin-right: var(--spacing-m);
}

.index-module_outputs-line__output-name__Q34li {
  width: 12.5rem;
}

.index-module_outputs-line__output-value__ztBs6 {
  width: 15.625rem;
}

.index-module_outputs-line__output-type__5l3Sx {
  width: 12.5rem;
}

.index-module_outputs-line__delete-button--hidden__DBNpK {
  visibility: hidden;
}

.index-module_outputs-line__fields__ze7sb {
  padding-right: 1.5rem;
}


.index-module_customer-api-form-setup__input-name__dtGUO {
  max-width: 25rem;
}

.index-module_customer-api-form-setup__LDDzX {
  margin-bottom: var(--spacing-xxl-2);
}
