.index-module_apis__container__DC3Wh {
  max-width: 72.125rem;
}

.index-module_table__cell__4Ca0L {
  width: 50px;
}

.index-module_customer-api__ehrv2 {
  min-height: 30rem;
}

.index-module_customer-apis__header__VVMbR {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.index-module_table__cell-version__m7BAZ {
  width: 50%;
}

.index-module_table__cell__u9LTi {
  width: 50px;
}

.index-module_suggested-apis__HAphj {
  min-height: 30rem;
}
