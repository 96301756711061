.index-module_table__cell__v3Cwi {
  width: 50px;
}

.index-module_smart-skills-content__mJb3R {
  max-width: 72.125rem;
  min-height: 30rem;
}

.index-module_smart-skills-content__header__MSNFh {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
