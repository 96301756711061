.index-module_accordion__XI1mY {
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--gray-300), 0 4px 8px 0 var(--gray-100);
}

.index-module_accordion--open__8DJ0n {
  padding-bottom: var(--spacing-m);
}

.index-module_accordion__header__Cz0zu {
  display: flex;
  flex-direction: row;
  margin: 0;
}

.index-module_accordion__collapse-button__LQUu3,
.index-module_accordion__delete-button__90nhO {
  display: flex;
  padding: 1rem;
  border: unset;
  background-color: unset;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.index-module_accordion__collapse-button__LQUu3 {
  width: 100%;
  align-items: center;
}

.index-module_accordion__collapse-button--open__NrJEd,
.index-module_accordion__delete-button--open__pDEHE {
  border-bottom-left-radius: 0 0;
  border-bottom-right-radius: 0 0;
}

.index-module_accordion__collapse-button__LQUu3:hover,
.index-module_accordion__delete-button__90nhO:hover {
  background-color: var(--gray-300);
}

.index-module_accordion__collapse-button__LQUu3:active,
.index-module_accordion__delete-button__90nhO:active {
  box-shadow: 0 4px 8px 0 var(--gray-100), inset 0 0 0 1px var(--gray-500), inset 0 0 4px 0 var(--gray-500);
}

.index-module_accordion__collapse-button__LQUu3:focus-visible,
.index-module_accordion__delete-button__90nhO:focus-visible {
  box-shadow: 0 4px 8px 0 var(--gray-300), inset 0 0 0 1px var(--blue-500);
}

.index-module_accordion__icon__RT4L5 {
  width: 1.25rem !important;
  height: 1.25rem !important;
  color: var(--gray-700) !important;
}

.index-module_accordion__icon--collapse__WVjzw {
  margin-right: var(--spacing-m);
}

.index-module_accordion__icon--delete__W7BVd {
  color: var(--dark) !important;
}

.index-module_accordion__title__gEFG7 {
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  letter-spacing: 1.5px;
  line-height: 1.375;
  text-transform: uppercase;
}

.index-module_accordion__content__aDQIZ {
  padding: var(--spacing-m) var(--spacing-m) 0 var(--spacing-m);
}

.index-module_banner__DU4b9 {
  display: flex;
  padding: 1.25rem 2rem;
  background-color: var(--account-color-500);
}

.index-module_banner__label__evCgm {
  overflow: hidden;
  margin: 0;
  margin-right: var(--spacing-xl);
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Not BEM to be more strenght than InternalLink & ExternalLink's CSS */
section > .index-module_banner__external-link__6rzJ-,
section > .index-module_banner__internal-link__Ds-2b {
  color: var(--light);
}

.index-module_icon__uafFn {
  fill: var(--primary-color-500);
}

.index-module_icon__jJWAX {
  fill: var(--primary-color-500);
}

.styles-module_spinner__E0H50 {
  --spinner-size: 24px;

  width: var(--spinner-size);
  height: var(--spinner-size);
  stroke: var(--primary-color-500);
}

.styles-module_spinner__E0H50 circle {
  animation: styles-module_spinner__E0H50 2s linear infinite;
  fill: transparent;
  stroke-dasharray: calc(3.14 * var(--spinner-size));
  stroke-linecap: round;
  stroke-width: 3;
  transform-origin: calc(0.5 * var(--spinner-size)) calc(0.5 * var(--spinner-size)) 0;
}

@keyframes styles-module_spinner__E0H50 {
  0% {
    stroke-dashoffset: calc(0.66 * var(--spinner-size));
    transform: rotate(0deg);
  }

  50% {
    stroke-dashoffset: calc(3.14 * var(--spinner-size));
    transform: rotate(720deg);
  }

  100% {
    stroke-dashoffset: calc(0.66 * var(--spinner-size));
    transform: rotate(1080deg);
  }
}

.index-module_default__k4aNt {
  width: 24px;
  height: 24px;
  color: var(--primary-color-500);
}

.index-module_button__MhULV {
  box-sizing: border-box;
  padding: var(--spacing-xxs) var(--spacing-l);
  border: none;
  background-color: var(--primary-color-500);
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  text-decoration: none;
}

.index-module_button__MhULV:hover {
  background-color: var(--primary-color-300);
}

.index-module_button__MhULV:active {
  background-color: var(--primary-color-700);
  box-shadow: inset 0 0 3px 0 var(--active-border-color);
}

.index-module_button__MhULV:focus-visible {
  box-shadow: inset 0 0 0 1px var(--blue-500), inset 0 0 0 2px var(--light);
}

.index-module_button--outline__bVRuV {
  background-color: var(--light-500);
  box-shadow: inset 0 0 0 1px var(--primary-color-500);
}

.index-module_button--outline__bVRuV:hover {
  background-color: var(--gray-300);
}

.index-module_button--outline__bVRuV:active {
  box-shadow: inset 0 0 0 1px var(--primary-color-500), inset 0 0 4px 0 var(--dark);
}

.index-module_button--outline__bVRuV:focus-visible {
  box-shadow: inset 0 0 0 2px var(--blue-500), inset 0 0 0 3px var(--primary-color-500);
}

.index-module_button--outline__bVRuV:focus-visible:active {
  background-color: var(--light-500);
  box-shadow: inset 0 0 0 2px var(--blue-500), inset 0 0 0 3px var(--primary-color-500), inset 0 0 6px 0 var(--dark);
}

.index-module_button--rounded__UcUH7 {
  border-radius: 16px;
}

.index-module_button--block__fHqQb {
  display: block;
  width: 100%;
  padding: 0.5rem 1.25rem;
}

.index-module_button--rounded-block__V7Zgl {
  border-radius: 20px;
}

.index-module_button--anchor__YauQ7 {
  display: inline-block;
}

.index-module_button--disabled__LZR5O,
.index-module_button--disabled__LZR5O:hover,
.index-module_button--disabled__LZR5O:active {
  background-color: var(--gray-500);
  box-shadow: unset;
  cursor: not-allowed;
}

.index-module_button--disabled-outline__7cyGf,
.index-module_button--disabled-outline__7cyGf:hover,
.index-module_button--disabled-outline__7cyGf:active {
  background-color: var(--light);
  box-shadow: inset 0 0 0 1px var(--gray-500);
}

.index-module_button--icon-only__P-gZY {
  padding: 0.25rem;
  border-radius: 16px;
}

.index-module_button--icon-only-outline-no-border__SpL0r {
  box-shadow: unset;
}

.index-module_button--icon-only-outline-no-border__SpL0r:focus-visible {
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.index-module_button--icon-only-outline-no-border__SpL0r:active {
  box-shadow: inset 0 0 4px 0 var(--dark);
}

.index-module_button__content__6asm8 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-module_button__content--block__32J6N {
  justify-content: center;
}

.index-module_button__left-icon__E7KsL,
.index-module_button__right-icon__5DHW8 {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  stroke: var(--light);
}

.index-module_button__loading-icon__dfFpk {
  width: 1.375rem;
  height: 1.375rem;
  stroke: var(--light);
}

.index-module_button__left-icon--outline__6evFz,
.index-module_button__right-icon--outline__vx4g8,
.index-module_button__loading-icon--outline__2-hQi {
  stroke: var(--primary-color-500);
}

.index-module_button__icon-only__NP-GF {
  stroke: var(--light);
}

.index-module_button__icon--outline-disabled__oizB2 {
  stroke: var(--gray-500);
}

.index-module_button__icon-only--outline__MZwHo,
.index-module_button__loading-icon--outline__2-hQi {
  stroke: var(--primary-color-500);
}

.index-module_button__left-icon__E7KsL {
  margin-right: var(--spacing-xxs);
}

.index-module_button__label__qXXPd {
  color: var(--light);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.index-module_button__label--right-icon__pdruj {
  margin-right: var(--spacing-xxs);
}

.index-module_button__label--outline__9XDX0 {
  color: var(--primary-color-500);
}

.index-module_button__label--outline-disabled__OfKGf {
  color: var(--gray-500);
}

.index-module_button-groupe-button__zcTIi {
  width: 6.25rem;
  box-sizing: border-box;
  padding: 0.75rem 0;
  border: none;
  background-color: var(--light);
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 var(--gray-100), inset 0 0 0 1px var(--gray-300);
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  text-decoration: none;
}

.index-module_button-groupe-button__zcTIi:hover {
  background-color: var(--gray-300);
}

.index-module_button-groupe-button__zcTIi:active {
  box-shadow: 0 4px 8px 0 var(--gray-100), inset 0 0 0 1px var(--gray-700), inset 0 0 8px 0 var(--gray-700);
}

.index-module_button-groupe-button__zcTIi:focus-visible {
  box-shadow: 0 4px 8px 0 var(--gray-100), inset 0 0 0 1px var(--blue-500);
}

.index-module_button-groupe-button--disabled__a5CCE,
.index-module_button-groupe-button--disabled__a5CCE:hover,
.index-module_button-groupe-button--disabled__a5CCE:active {
  background-color: var(--gray-300);
  box-shadow: unset;
  cursor: not-allowed;
}

.index-module_button-groupe-button__content__044dg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.index-module_button-groupe-button__icon-container__o6kFL {
  display: flex;
  margin-bottom: var(--spacing-xs);
}

.index-module_button-groupe-button__icon__Yu9Va {
  color: var(--gray-700);
}

.index-module_button-groupe-button__icon--disabled__oiFPM {
  color: var(--gray-500);
}

.index-module_button-groupe-button__label__J6UUB {
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
}

.index-module_button-groupe-button__label--disabled__3Nw-N {
  color: var(--gray-500);
}

.index-module_button-group__980pL {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem;
  background-color: var(--light);
  border-radius: 8px;
  box-shadow: 0 10px 20px 0 var(--gray-400);
}

.index-module_button-group__980pL button {
  margin-right: var(--spacing-xs);
}

.index-module_button-group__980pL button:last-child {
  margin-right: 0;
}

.index-module_heatmap-chart__ueaBA {
  width: auto;
  min-height: 500px;
}

.index-module_line-chart__w1NeX {
  width: auto;
  min-height: 400px;
}

.index-module_pie-chart__ib9f2 {
  width: 1200px;
  min-height: 400px;
}

.index-module_sankey-chart__xxzsU {
  width: auto;
  min-height: 600px;
}

.index-module_stacked-line-chart__bqRj6 {
  width: auto;
  min-height: 400px;
}

.index-module_data-grid-cell__5foiq {
  overflow: hidden;
  height: 64px;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.index-module_data-grid-cell__content__Z5ECL {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.index-module_data-grid-cell__label--describe__9dSSE {
  margin-bottom: var(--spacing-xxs);
}

.index-module_data-grid-cell__label__5tGqt,
.index-module_data-grid-cell__description__wlmKs {
  overflow: hidden;
  text-overflow: ellipsis;
}

.index-module_data-grid-cell-collapse__I3F38 {
  overflow: hidden;
  white-space: nowrap;
}

.index-module_data-grid-cell-collapse__container__hQyof {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: center;
}

.index-module_data-grid-cell-collapse__icon__1gkjR {
  width: 1rem;
  height: 1rem;
  color: var(--gray-600);
}

.index-module_data-grid-cell-collapse__button__El6RR {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.75rem;
  border: none;
  background-color: unset;
  border-radius: 100%;
  border-spacing: 0;
  cursor: pointer;
  outline: inherit;
}

.index-module_data-grid-cell-collapse__button__El6RR:hover {
  background-color: var(--gray-300);
}

.index-module_data-grid-cell-collapse__button__El6RR:active {
  background-color: var(--gray-400);
}

.index-module_data-grid-cell-collapse__button__El6RR:focus-visible {
  box-shadow: inset 0 0 0 1px var(--blue-500), inset 0 0 0 2px var(--light);
}

.index-module_data-grid-row__UmVkB {
  border-bottom: 1px solid var(--gray-400);
}

.index-module_data-grid-empty-content__ZmEcz {
  border-bottom: 1px solid var(--gray-400);
}

.index-module_data-grid-empty-content__cell__oDtyI {
  height: 100%;
}

.index-module_data-grid-empty-content__icon__pBcOj {
  width: 8rem;
  height: 8rem;
  stroke-width: 1;
}

.index-module_data-grid-title__PDHXP {
  overflow: hidden;
  height: 50px;
  text-align: left;
  white-space: nowrap;
}

.index-module_data-grid-title__button__cszCR {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.75rem 1rem;
  border: none;
  background-color: unset;
  border-spacing: 0;
  cursor: pointer;
  outline: inherit;
}

.index-module_data-grid-title__sorting-icon__Ps5vZ {
  width: 0.75rem;
  height: 0.75rem;
  color: var(--gray-500);
  visibility: hidden;
}

.index-module_data-grid-title__button__cszCR:hover .index-module_data-grid-title__sorting-icon__Ps5vZ {
  visibility: visible;
}

.index-module_data-grid-title__button__cszCR:active {
  background-color: var(--gray-300);
}

.index-module_data-grid-title__button__cszCR:focus-visible {
  box-shadow: inset 0 0 0 1px var(--blue-500), inset 0 0 0 2px var(--light);
}

.index-module_data-grid-title__content__uEKvj {
  margin-right: var(--spacing-xs);
  font-weight: normal;
}

.index-module_data-grid-title__sorting-icon--active__0wZyR {
  color: var(--gray-700);
  visibility: visible;
}

.index-module_data-grid-header__line__KEr32 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: var(--light);
  box-shadow: inset 0 -1px 0  var(--gray-400);
}

.index-module_sub-data-grid__3hOQC {
  width: 50%;
  border-collapse: collapse;
}

.index-module_sub-data-grid__header-cell__afJwX {
  position: unset;
}

.index-module_sub-data-grid__row__V2gxy:last-child {
  border: unset;
}

.index-module_data-grid__-KetV {
  width: 100%;
  border-collapse: collapse;
}

.index-module_data-grid--empty__RemPr {
  height: 100%;
}

.index-module_data-grid__sub-data-content-cell__JnBtN {
  padding: var(--spacing-m) 0;
  border-collapse: collapse;
}

.index-module_data-grid__sub-data-grid__ciApW {
  width: 100%;
  border-collapse: collapse;
}

.index-module_divider__Qo-Ea {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.125rem 0;
  background-color: var(--light);
}

.index-module_divider__left-line__59PQ- {
  width: 100%;
  height: 1px;
  background-color: var(--gray-600);
}

.index-module_divider__label__cjSP5 {
  margin: 0 1rem;
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.index-module_divider__right-line__txBn1 {
  width: 100%;
  height: 1px;
  background-color: var(--gray-600);
}

.index-module_divider__anchor__ocNUj {
  margin: 0 1rem;
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.index-module_divider__anchor__ocNUj:hover {
  color: var(--gray-500);
}

.index-module_divider__anchor__ocNUj:active {
  color: var(--gray-700);
}

.index-module_drag-and-drop-item__acE5H {
  margin-top: var(--spacing-m);
  list-style: none;
}

.index-module_drag-and-drop-item__acE5H:first-child {
  margin-top: 0;
}

.index-module_drag-and-drop-item--drag-to-top-is-on-current-element__OcVpE {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.index-module_drag-and-drop-item--drag-to-bottom-is-on-current-element__NVdpl {
  display: flex;
  flex-direction: column;
}

.index-module_drag-and-drop-item--drag-to-bottom-is-on-current-element__NVdpl + li {
  margin-top: 0;
}

.index-module_drag-and-drop-item__new-position__eTs4w {
  display: none;
  width: 100%;
  padding: 4rem 0;
  margin: var(--spacing-xl) 0;
  background-color: var(--gray-300);
  border-radius: 8px;
}

.index-module_drag-and-drop-item__new-position--visible__fd74c {
  display: block;
}

.index-module_drag-and-drop-item__container__hH-Co {
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--gray-300), 0 8px 16px 0 var(--gray-100);
  cursor: grab;
}

.index-module_drag-and-drop-item__container--dragged__cDV6U {
  background-color: var(--gray-300);
  box-shadow: 0 10px 20px 0 var(--gray-500), inset 0 0 0 1px var(--gray-300);
}

.index-module_drag-and-drop-item__container--drag-is-on-current-element__fTHi4 {
  box-shadow: 0 4px 8px 0 var(--gray-300), inset 0 0 0 1px var(--blue-500);
}

.index-module_drag-and-drop-item__container--drag-is-on-current-element-inline__Lwqzn {
  box-shadow: 0 4px 8px 0 var(--gray-300), inset 0 0 0 1px var(--blue-500) !important;
}

.index-module_drag-and-drop-item__container--inline__coR2Y {
  display: flex;
  box-shadow: none;
}

.index-module_drag-and-drop-item__container--dragged-inline__ZgZHt {
  background-color: unset;
  box-shadow: unset;
}

.index-module_drag-and-drop-item__container__hH-Co:active {
  cursor: grabbing;
}

.index-module_drag-and-drop-item__header__-KdQ2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.index-module_drag-and-drop-item__left-header__-NOSO {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem;
  overflow-x: hidden;
}

.index-module_drag-and-drop-item__left-header--dragged-inline__2NSvd {
  background-color: var(--gray-300);
}

.index-module_drag-and-drop-item__left-header--inline__PoktW {
  padding: 0.25rem;
  margin-right: var(--spacing-xs);
  border-radius: 50%;
}

.index-module_drag-and-drop-item__icon__9uT7F {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

.index-module_drag-and-drop-item__icon--drag__MDegP {
  margin-right: var(--spacing-m);
  color: var(--gray-700);
}

.index-module_drag-and-drop-item__icon--inline__YlQEB {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0;
}

.index-module_drag-and-drop-item__title__pkvXZ {
  margin: 0;
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 1.5px;
  line-height: 1.375;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.index-module_drag-and-drop-item__close-button__tW9Pa {
  display: flex;
  padding: 1rem;
  border: unset;
  background-color: unset;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.index-module_drag-and-drop-item__close-button__tW9Pa:hover {
  background-color: var(--gray-300);
}

.index-module_drag-and-drop-item__close-button__tW9Pa:active {
  box-shadow: 0 4px 8px 0 var(--gray-100), inset 0 0 0 1px var(--gray-500), inset 0 0 4px 0 var(--gray-500);
}

.index-module_drag-and-drop-item__close-button__tW9Pa:focus-visible {
  box-shadow: 0 4px 8px 0 var(--gray-300), inset 0 0 0 1px var(--blue-500);
}

.index-module_drag-and-drop-item__icon--delete__1y2V- {
  color: var(--dark);
}

.index-module_drag-and-drop-item__content__fWR6i {
  display: flex;
  padding: 1rem;
  cursor: default;
}

.index-module_drag-and-drop-item__content--inline__oSCir {
  width: 100%;
  align-items: center;
  padding: 0;
}

.index-module_drag-and-drop-item__content--dragged__i-RZX {
  background-color: var(--light);
}

.index-module_drag-and-drop__list__ojlsf {
  padding: 0;
  margin: 0;
}

.index-module_error-message__12k4k {
  display: flex;
  width: 100%;
  padding: 0.5rem 0;
  background-color: var(--light);
  color: var(--danger-color-500);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  overflow-wrap: anywhere;
  text-align: justify;
}

.index-module_error-message--hidden__JosWj {
  visibility: hidden;
}

.index-module_error-message-group__9D9m0 {
  flex-direction: column;
}

.index-module_error-message-group--hidden__-huBd {
  display: inline;
  visibility: hidden;
}

.index-module_error-message--group__XiAzU {
  padding: 0 0 var(--spacing-xxs) 0;
}

.index-module_error-message--group__XiAzU:first-child {
  padding-top: 0.5rem;
}

.index-module_error-message--group__XiAzU:last-child {
  padding-bottom: 0.5rem;
}

.index-module_error-message--undefined__tAp8t {
  display: flex;
  height: 0.75rem;
  padding: 0.5rem 0;
}

/* ==========================================================================
   Custom Anchor
   ========================================================================== */

.index-module_external-link__6uHce {
  display: flex;
  align-items: center;
  color: var(--blue-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.38;
}

.index-module_external-link__6uHce:hover {
  color: var(--blue-300);
}

.index-module_external-link__6uHce:active {
  color: var(--blue-700);
}

.index-module_external-link__label__8rOJA {
  margin-right: var(--spacing-xxs);
}

.index-module_external-link__icon__30VJp {
  display: flex;
  width: var(--spacing-m);
  height: var(--spacing-m);
  color: var(--blue-500);
}

.index-module_external-link__6uHce:hover .index-module_external-link__icon__30VJp {
  color: var(--blue-300);
}

.index-module_external-link__6uHce:active .index-module_external-link__icon__30VJp {
  color: var(--blue-700);
}

/* ==========================================================================
   Zeplin InputErrorMessage
   ========================================================================== */

.zeplin-module_input-error__VohQP .zeplin-module_background__HkXRq {
  padding: 0.5rem 0;
  background-color: var(--light);
}

.zeplin-module_input-error__VohQP .zeplin-module_label__pv-Jt {
  font-family: Rubik;
  font-size: 0.75rem;
  line-height: 1;
  color: var(--danger-color-500);
}

/* ==========================================================================
   Custom InputErrorMessage
   ========================================================================== */

.custom-module_input-error__CFfRh .custom-module_background__WlrHX {
  width: 100%;
  background: none;
  overflow-wrap: anywhere;
  text-align: justify;
}

.custom-module_input-error__CFfRh {
  display: flex;
  flex-direction: column;
}

.custom-module_input-error__CFfRh .custom-module_label__haNmm {
  padding-right: 0;
  font-weight: normal;
  letter-spacing: normal;
}

.custom-module_input-error__CFfRh .custom-module_group-label__e7SsZ {
  padding: 0 0 var(--spacing-xxs) 0;
}

.custom-module_input-error__CFfRh .custom-module_group-label__e7SsZ:first-child {
  padding-top: 0.5rem;
}

.custom-module_input-error__CFfRh .custom-module_group-label__e7SsZ:last-child {
  padding-bottom: 0.5rem;
}

/* ==========================================================================
   Zeplin Input
   ========================================================================== */

/* Input-label
   ========================================================================== */

.zeplin-module_input-label__3wAzM .zeplin-module_background__l3lis {
  padding: 0 0 0.75rem;
  background-color: var(--light);
}

.zeplin-module_input-label__3wAzM .zeplin-module_label__1-cPP {
  font-family: Rubik;
  font-size: 0.75rem;
  letter-spacing: 1.13px;
  color: var(--gray-600);
}

/* Input
   ========================================================================== */

.zeplin-module_input__GI8PG .zeplin-module_background__l3lis {
  border-radius: 5px;
  background-color: var(--gray-300);
  padding: 0.5rem 1rem;
  box-shadow: inset 0 0 0 1px var(--teal-200);
}

.zeplin-module_input__GI8PG .zeplin-module_label__1-cPP {
  font-family: Rubik;
  font-size: 1rem;
  line-height: 1.38;
  color: var(--gray-700);
}

.zeplin-module_input-side-infos__wm5K0 {
  font-family: Rubik;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.38;
  color: var(--dark);
}

/* Input danger
   ========================================================================== */

.zeplin-module_input__GI8PG.zeplin-module_danger__fSCwe .zeplin-module_background__l3lis {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  box-shadow: inset 0 0 0 1px var(--danger-color-500);
}

/* ==========================================================================
   Custom Input
   ========================================================================== */

.custom-module_input-label__P6iOF {
  display: flex;
  flex-direction: column;
}

.custom-module_input__ZIh8C {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.custom-module_input__ZIh8C .custom-module_background__8jw79 {
  width: 100%;
  border: none;
}

.custom-module_input-label__P6iOF > .custom-module_background__8jw79 {
  display: flex;
  background: none;
}

.custom-module_input__ZIh8C .custom-module_background__8jw79:focus {
  box-shadow: inset 0 0 0 1px var(--purple-500), inset 0 0 0 2px var(--blue-500);
  outline: none;
}

.custom-module_input__ZIh8C .custom-module_label__4FbPN {
  letter-spacing: normal;
}

.custom-module_input__ZIh8C .custom-module_label__4FbPN::placeholder {
  color: var(--gray-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  letter-spacing: normal;
  line-height: 1.38;
}

.custom-module_input-label__P6iOF > .custom-module_background__8jw79 > .custom-module_label__4FbPN {
  text-transform: uppercase;
}

.custom-module_input__ZIh8C.custom-module_danger__AmuEG .custom-module_background__8jw79,
.custom-module_input__ZIh8C.custom-module_success__n11Y- .custom-module_background__8jw79,
.custom-module_input__ZIh8C.custom-module_password__SvBxj .custom-module_background__8jw79 {
  padding: 0.5rem 2rem 0.5rem 1rem;
}

.custom-module_input__ZIh8C.custom-module_danger__AmuEG .custom-module_background__8jw79:focus {
  box-shadow: none;
  box-shadow: inset 0 0 0 1px var(--blue-500), inset 0 0 0 2px var(--danger-color-500);
  outline: none;
}

.custom-module_input__ZIh8C .custom-module_icon__d1AUe {
  width: 1rem;
  height: 1rem;
}

.custom-module_input__ZIh8C .custom-module_icon__d1AUe.custom-module_icon-success__WmiM3,
.custom-module_input__ZIh8C .custom-module_icon__d1AUe.custom-module_icon-danger__ulPSt {
  position: absolute;
  right: var(--spacing-xs);
  display: flex;
  width: 1rem;
  height: 1rem;
  align-items: center;
}

.custom-module_input__ZIh8C .custom-module_icon-success__WmiM3 {
  stroke: var(--success-color-500);
}

.custom-module_input__ZIh8C .custom-module_icon-danger__ulPSt {
  stroke: var(--danger-color-500);
}

.custom-module_input__ZIh8C .custom-module_button-icon-password__kH4pa {
  position: absolute;
  right: var(--spacing-xs);
  display: flex;
  display: none;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

.custom-module_input-container__fgeDT {
  display: flex;
  align-items: baseline;
}

.custom-module_input-container--error__4TyHL {
  align-items: center;
}

.custom-module_input-container--block__Bc-z4 {
  display: block;
}

.zeplin-module_input-select-button__SIpfp .zeplin-module_background__ASZk5 {
  width: 17rem;
  height: 2.375rem;
  border-radius: 5px;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  background-color: var(--gray-300);
  box-shadow: inset 0 0 0 1px var(--teal-200);
}

.zeplin-module_input-select-button__SIpfp.zeplin-module_danger__Tm0fV .zeplin-module_background__ASZk5 {
  box-shadow: inset 0 0 0 1px var(--danger-color-500);
}

.zeplin-module_input-select-button__SIpfp .zeplin-module_label__LuaWF {
  width: 14rem;
  height: 1.375rem;
  margin: 0 0.5rem 0 0;
  font-family: Rubik;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--gray-700);
}

.custom-module_input-select-button__PgsND {
  position: relative;
}

.custom-module_input-select-button--disabled__KaZC8 {
  cursor: not-allowed;
}

.custom-module_input-select-button__PgsND .custom-module_background__rBgj8 {
  width: 100%;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
}

.custom-module_input-select-button__PgsND .custom-module_label__KooBo {
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.38;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.custom-module_input-select-button__PgsND .custom-module_label--disabled__ycFbl {
  color: var(--gray-500);
}

.custom-module_icon-container__jIpv- {
  position: absolute;
  top: 0;
  right: var(--spacing-xs);
  display: flex;
  height: 100%;
  align-items: center;
}

.custom-module_icon-container__jIpv- .custom-module_icon__NDDQU {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  color: var(--gray-700);
}

.custom-module_icon-container__jIpv- .custom-module_icon--disabled__ZPd3k {
  color: var(--gray-500);
}

.custom-module_icon-container__jIpv- .custom-module_error-icon__3Lm5L {
  margin-right: var(--spacing-xs);
  stroke: var(--danger-color-500);
}

.custom-module_icon-container__jIpv- .custom-module_success-icon__fvP-A {
  margin-right: var(--spacing-xs);
  stroke: var(--success-color-500);
}

.custom-module_input-select-button__PgsND .custom-module_background__rBgj8:focus {
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.custom-module_input-select-button__PgsND.custom-module_danger__Vvfqw .custom-module_background__rBgj8 {
  padding: 0.5rem 3rem 0.5rem 1rem;
}

.custom-module_input-select-button__PgsND.custom-module_danger__Vvfqw .custom-module_background__rBgj8:focus {
  box-shadow: inset 0 0 0 1px var(--blue-500), inset 0 0 0 2px var(--danger-color-500);
}

.index-module_label__TdN4d {
  padding: 0 0 0.75rem;
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1.13px;
  text-transform: uppercase;
}

.index-module_fieldset__2r9KT {
  display: flex;
  flex-direction: column;
  padding: 0;
  border: none;
  margin: 0 0 var(--spacing-xxl) 0;
}

.index-module_fieldset--error__sOwIK {
  margin-bottom: 0 !important;
}

.index-module_fieldset__2r9KT:last-child {
  margin-right: 0;
}

.index-module_fieldset__2r9KT div:last-of-type > span,
.index-module_fieldset__2r9KT div:last-of-type {
  margin-bottom: 0;
}


.index-module_icon-rounded-container__Bj5ca {
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color-100);
  border-radius: 50%;
}

.index-module_icon__jS7yW {
  width: 1.5rem;
  height: 1.5rem;
}

.index-module_label__zkSVk {
  padding: 0 0 0.75rem;
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1.13px;
  text-transform: uppercase;
}

.index-module_li-element__0sWuD {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xs);
  background-color: var(--light);
  list-style: none;
}

.index-module_li-element__0sWuD::before {
  display: inline-block;
  width: 4px;
  height: 4px;
  flex-shrink: 0;
  margin-right: var(--spacing-m);
  margin-left: 0.625rem;
  background-color: var(--dark);
  border-radius: 50%;
  content: "";
}

.index-module_li-element__label__FtJ-C {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.index-module_li-element-icon__9dSUB {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xs);
  background-color: var(--light);
  list-style: none;
}

.index-module_li-element-icon__icon__HpZ4D {
  width: 1.375rem;
  height: 1.375rem;
  flex-shrink: 0;
  margin-right: var(--spacing-xs);
}

.index-module_li-element-icon__label__525jb {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.index-module_li-element-no-margin__WZ-N5 {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xs);
  background-color: var(--light);
  list-style: none;
}

.index-module_li-element-no-margin__label__foP-b {
  width: 100%;
  margin: 0 0 var(--spacing--xxs) 0;
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
}

.index-module_li-element-none__EARJc {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xs);
  margin-left: calc(var(--spacing-l) + 0.625rem);
  background-color: var(--light);
  list-style: none;
}

.index-module_li-element-none__label__IqMgy {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.index-module_li-element-text__VClKU {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xs);
  background-color: var(--light);
  list-style: none;
}

.index-module_li-element-text__bullet__RdC-X {
  width: 4rem;
  max-width: 4rem;
  margin-right: 1rem;
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375;
  text-align: left;
  word-wrap: break-word;
}

.index-module_li-element-text__label__AsWd9 {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

@media (min-width: 320px) {
  .index-module_section-list__UdPds {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .index-module_list__title__ARh4a {
    margin: 0 0 var(--spacing-s) 0;
    color: var(--dark);
    font-family: Rubik, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375;
  }

  .index-module_list__S-OX4 {
    margin-block: 0;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
  }

  .index-module_list--inline__BPxWA li {
    width: calc(50% - var(--spacing-l));
    margin-right: var(--spacing-l);
  }

  .index-module_list__S-OX4 li:last-child {
    margin-bottom: 0;
  }

  .index-module_list--inline__BPxWA {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .index-module_list--inline__BPxWA li {
    width: calc(33% - var(--spacing-l));
  }
}

@media (min-width: 1024px) {
  .index-module_list--inline__BPxWA {
    flex-wrap: nowrap;
  }

  .index-module_list--inline__BPxWA li {
    width: unset;
  }
}

.index-module_logo-agent__EUYSC {
  height: 100px;
}

.index-module_logo-analytics__t1krE {
  height: 100px;
}

.index-module_logo-bot__2wUE9 {
  height: 100px;
}

.index-module_logo-tolk__3wsgZ {
  height: 100px;
}

.index-module_logo-tolk__ud-aU {
  height: 100px;
}


.index-module_menu__s0EhM {
  display: flex;
  margin-bottom: var(--spacing-s);
  list-style: none;
}

.index-module_menu__button__c2CFs {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: none;
  margin-bottom: var(--spacing-xxs);
  background-color: var(--light);
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  text-align: left;
  text-decoration: none;
}

.index-module_menu__button__c2CFs:hover {
  background-color: var(--gray-300);
}

.index-module_menu__button__c2CFs:active {
  background-color: var(--gray-400);
}

.index-module_menu__button__c2CFs:focus-visible {
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.index-module_menu__icon-nav__y2Zli {
  width: 0.875rem;
  height: 0.875rem;
  flex-shrink: 0;
  margin-right: var(--spacing-s);
  stroke: var(--gray-700);
}

.index-module_menu__icon-nav--link__m4Rzj {
  margin-right: 0;
}

.index-module_menu__label__7v9FQ {
  width: 7.625rem;
  margin: 0 0.75rem 0 0.75rem;
  margin-left: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index-module_menu__button--active__iOZp4 {
  background-color: var(--gray-400);
}

.index-module_menu__button--active__iOZp4:hover {
  background-color: var(--gray-400);
}

.index-module_menu__button--active__iOZp4:active {
  background-color: var(--gray-400);
}

/* ==========================================================================
   Sidebar section
   ========================================================================== */
.index-module_menu-accordion__b3AUK {
  display: block;
  margin-bottom: var(--spacing-s);
  list-style: none;
}

.index-module_menu-accordion__button__pbZKr {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: none;
  margin-bottom: var(--spacing-xxs);
  background-color: var(--light);
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  text-align: left;
}

.index-module_menu-accordion__button__pbZKr:hover {
  background-color: var(--gray-300);
}

.index-module_menu-accordion__button__pbZKr:active {
  background-color: var(--gray-400);
}

.index-module_menu-accordion__button__pbZKr:focus-visible {
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.index-module_menu-accordion__icon-nav__0bGDW {
  width: 0.875rem;
  height: 0.875rem;
  flex-shrink: 0;
  margin-right: var(--spacing-s);
  stroke: var(--gray-700);
}

.index-module_menu-accordion__icon-nav--open__z4k2D {
  stroke: var(--primary-color-500);
}

.index-module_menu-accordion__label__03dAQ {
  width: 7.625rem;
  margin: 0 0.75rem 0 0.75rem;
  margin-left: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index-module_menu-accordion__label__03dAQ.index-module_menu-accordion__label--open__kg36h {
  color: var(--primary-color-500);
}

.index-module_menu-accordion__icon-chevron-down__P3vLN {
  width: 0.875rem;
  height: 0.875rem;
  flex-shrink: 0;
  justify-self: end;
  stroke: var(--gray-700);
}

.index-module_menu-accordion__icon-chevron-down--open__isCCK {
  stroke: var(--primary-color-500);
  transform: rotate(180deg);
}

.index-module_menu-accordion__list__9u7YJ {
  padding: 0;
  margin: 0;
}

.index-module_menu-accordion__list__9u7YJ li:last-child {
  margin-bottom: 0;
}

/* ==========================================================================
   Sidebar element button
   ========================================================================== */

.index-module_li-element__pG48W {
  margin-bottom: var(--spacing-xs);
  list-style: none;
}

.index-module_li-element__button__V5v5p {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0;
  padding: 0.25rem 0.5rem 0.25rem 0.875rem;
  border: none;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.index-module_li-element__button__V5v5p:hover {
  background-color: var(--gray-300);
}

.index-module_li-element__label__UMBuT {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index-module_li-element__button__V5v5p:active .index-module_li-element__label__UMBuT {
  color: var(--gray-700);
}

.index-module_li-element__button--active__3p2U-:active .index-module_li-element__label__UMBuT {
  color: var(--gray-600);
}

.index-module_li-element__button__V5v5p:focus-visible {
  background-color: var(--light);
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.index-module_li-element__button__V5v5p::before {
  display: inline-block;
  width: 3px;
  height: 3px;
  flex-shrink: 0;
  margin: 0.563rem 0 0.625rem 0;
  margin-right: 1.344rem;
  background-color: var(--gray-600);
  border-radius: 50%;
  content: "";
}

.index-module_li-element__button__V5v5p:active::before {
  background-color: var(--gray-700);
}

.index-module_li-element__button--active__3p2U-:active::before {
  background-color: var(--gray-600);
}

.index-module_li-element__button--active__3p2U- {
  background-color: var(--gray-300);
}

/* ==========================================================================
   Sidebar element button
   ========================================================================== */

.index-module_li-element__jMas6 {
  margin-bottom: var(--spacing-xs);
  list-style: none;
}

.index-module_li-element__anchor__e2LDE {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0;
  padding: 0.25rem 0.5rem 0.25rem 0.875rem;
  border: none;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  text-decoration: unset;
}

.index-module_li-element__anchor__e2LDE:hover {
  background-color: var(--gray-300);
}

.index-module_li-element__label__br89H {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index-module_li-element__anchor__e2LDE:active .index-module_li-element__label__br89H {
  color: var(--gray-700);
}

.index-module_li-element__anchor__e2LDE:focus-visible {
  background-color: var(--light);
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.index-module_li-element__anchor__e2LDE::before {
  display: inline-block;
  width: 3px;
  height: 3px;
  flex-shrink: 0;
  margin: 0.563rem 0 0.625rem 0;
  margin-right: 1.344rem;
  background-color: var(--gray-600);
  border-radius: 50%;
  content: "";
}

.index-module_li-element__anchor__e2LDE:active::before {
  background-color: var(--gray-700);
}

.index-module_navbar__rWKFy {
  display: flex;
  width: calc(13.375rem - 0.75rem - 0.75rem);
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 0.75rem 0 0.75rem;
  border: solid 0 var(--light);
  background-color: var(--light);
}

.index-module_navbar__list__WKXvM {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
  overflow-y: auto;
}

.index-module_navbar__footer__cfI-y {
  margin-bottom: 2rem;
}

.index-module_page-content-description__urtEA {
  margin: 0 0 2rem 0;
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.index-module_page-content-description__tix3u {
  height: 100%;
  margin: auto 0;
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  text-align: center;
}

.index-module_error__qEe-4 {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;
}

.index-module_error__message-container__z5blE {
  display: flex;
}

.index-module_error__message__MWtE6 {
  margin: 0 0 var(--spacing-l) 0;
}

.index-module_error__icon-container__Xfkdc {
  margin: 0 var(--spacing-s) 0 0;
}

.index-module_error__icon-color__lMaVC {
  stroke: var(--danger-color-500);
}

.index-module_loading__NpWcW {
  height: 100%;
  margin: auto 0;
  text-align: center;
}

.index-module_loading__container__4PGLa {
  position: relative;
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
}

svg.index-module_loading__icon__QNtaJ {
  width: var(--spacing-xxl-2);
  height: var(--spacing-xxl-2);
}

.index-module_page-content-title__8X2Uz {
  margin: 0 0 1rem;
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 1.5px;
  line-height: 1.375;
  text-transform: uppercase;
}

.index-module_page-content__-tQXh {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  margin-bottom: var(--spacing-xxl-2);
  background-color: var(--light);
  border-radius: 8px;
  box-shadow: 0 10px 20px 0 var(--gray-400);
}

.index-module_error__6glT6 {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-50%);
}

.index-module_error__icon__yURkG {
  stroke: var(--danger-color-500);
}

.index-module_error__message-container__IFw2W {
  display: flex;
}

.index-module_error__message__Jv5DJ {
  margin: 0 0 var(--spacing-l) 0;
}

.index-module_error__icon-container__USAPc {
  margin: 0 var(--spacing-s) 0 0;
}

.index-module_error__icon-color__uCSMT {
  stroke: var(--danger-color-500);
}

.index-module_loading__Aw4lv {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  transform: translateY(-50%);
}

svg.index-module_loading__icon__8SHl2 {
  width: var(--spacing-xxl-2);
  height: var(--spacing-xxl-2);
}

.zeplin-module_label__ihViI {
  margin: 0 18.063rem 2rem 0;
  font-family: Rubik;
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 1.14;
  color: var(--dark);
}

.custom-module_label__RlhnD {
  margin: 0;
  margin-bottom: 2rem;
}

.index-module_page-layout__95c87 {
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
  padding: 2rem 1rem 6.75rem 1rem;
  background-color: var(--gray-300);
}

@media (min-width: 768px) {
  .index-module_page-layout__95c87 {
    padding: 2rem 2rem 6.75rem 2rem;
  }
}

/* ==========================================================================
   Custom Radio
   ========================================================================== */

.index-module_radio__iJG3X {
  display: flex;
  margin-bottom: var(--spacing-xs);
  cursor: pointer;
}

.index-module_radio__default-input__GPHgj {
  width: 0;
  height: 0;
  margin: 0;
  opacity: 0;
}

.index-module_radio__input__7FHYG {
  display: flex;
}

.index-module_radio__control__KVQYo {
  display: flex;
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin: 0.125rem 1rem 0.125rem 0;
  background-color: var(--gray-300);
  border-radius: 9px;
  box-shadow: inset 0 0 0 1px var(--teal-200);
}

input + .index-module_radio__control__KVQYo::before {
  width: 0.625rem;
  height: 0.625rem;
  background-color: var(--primary-color-500);
  border-radius: 5px;
  content: "";
  transform: scale(0);
}

input:hover + .index-module_radio__control__KVQYo {
  box-shadow: inset 0 0 0 1px var(--gray-500);
}

input:checked + .index-module_radio__control__KVQYo::before {
  transform: scale(1);
}

input:focus-visible + .index-module_radio__control__KVQYo {
  box-shadow: inset 0 0 1px 2px var(--blue-500);
}

.index-module_body-cell__TnsgW {
  border-top: 1px solid var(--gray-400);
  border-bottom: 1px solid var(--gray-400);
}

.index-module_body-cell__TnsgW:first-child {
  border-left: 1px solid var(--gray-400);
}

.index-module_body-cell__TnsgW:last-child {
  border-right: 1px solid var(--gray-400);
}

.index-module_body-cell__container__931gM {
  display: flex;
  min-width: var(--spacing-xxl);
  height: var(--spacing-m);
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1.5rem 1rem;
}

.index-module_body-cell__container--description__swauw {
  padding: 1rem;
}

.index-module_body-cell__children__lhxD3 {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
}

.index-module_body-cell__children--description__rSbWQ {
  margin: 0 0.25rem 0.125rem 0;
}

.index-module_body-cell__description__lZnhT {
  margin: 0.125rem 0 0;
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
}

.index-module_body-cell__children--centered__o1KlG {
  display: flex;
  justify-content: center;
}

.zeplin-module_table-title__As-nH .zeplin-module_background__16fGk {
  padding: 0.75rem 1rem;
}

.zeplin-module_table-title__As-nH .zeplin-module_title__6XPJJ {
  font-family: Rubik;
  font-size: 0.75rem;
  letter-spacing: 1px;
  color: var(--gray-600);
}

.custom-module_table-title__AA7D3 {
  text-align: unset;
}

.custom-module_table-title__AA7D3 .custom-module_background__qX8Jv {
  display: flex;
  padding: 0 1rem;
}

.custom-module_table-title__AA7D3 .custom-module_title__YriB5 {
  font-weight: normal;
}

.custom-module_table-row__Azw-J {
  padding: 0;
}

.custom-module_table__0NR38 {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

.index-module_body-cell__iCi9X {
  border-top: 1px solid var(--gray-400);
  border-bottom: 1px solid var(--gray-400);
}

.index-module_body-cell__iCi9X:first-child {
  border-left: 1px solid var(--gray-400);
}

.index-module_body-cell__iCi9X:last-child {
  border-right: 1px solid var(--gray-400);
}

.index-module_body-cell__container__Yk9QV {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1.5rem 1rem;
  border: none;
  background-color: unset;
  cursor: pointer;
}

.index-module_body-cell__container__Yk9QV:focus-visible {
  box-shadow: inset 0 0 0 2px var(--blue-500), inset 0 0 0 3px var(--primary-color-500);
}

.index-module_body-cell__container--description__-4MQ3 {
  padding: 1rem;
}

.index-module_body-cell__children__nlSJp {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
}

.index-module_body-cell__children--description__SeiJL {
  margin: 0 0.25rem 0.125rem 0;
}

.index-module_body-cell__description__zzVdT {
  margin: 0.125rem 0 0;
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
}

.index-module_body-cell__children--centered__PPSnh {
  display: flex;
  justify-content: center;
}

.index-module_text-area__Fkndz {
  width: 100%;
}

.index-module_text-area__label__su0f8 {
  display: flex;
  flex-direction: column;
}

.index-module_text-area__label-text__aoQw0 {
  padding: 0 0 0.75rem;
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1.13px;
  text-transform: uppercase;
}

.index-module_text-area__text-area__1E3QP {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border: unset;
  margin: 0;
  background-color: var(--gray-300);
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px var(--teal-200);
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.index-module_text-area__text-area--not-resizable__H03EH {
  resize: none;
}

.index-module_text-area__text-area--error__towlb {
  box-shadow: inset 0 0 0 1px var(--danger-color-500);
}

.index-module_text-area__text-area--success__0cRTL {
  box-shadow: inset 0 0 0 1px var(--success-color-500);
}

.index-module_text-area__text-area__1E3QP:focus {
  box-shadow: inset 0 0 0 1px var(--purple-500), inset 0 0 0 2px var(--blue-500);
  outline: none;
}

.index-module_text-area__error-container__uNPDf {
  display: flex;
  flex-direction: column;
}

.index-module_text-area__error__WPhXX {
  padding: 0.5rem 0;
  color: var(--danger-color-500);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  font-weight: normal;
  visibility: hidden;
}

.index-module_text-area__error--visible__nVc6m {
  visibility: visible;
}

.index-module_logo-container__WxxRb {
  display: flex;
}

.index-module_logo-container__analytics__PynUV {
  height: 1.75rem;
}

.index-module_logo-container__iZ3oz {
  display: flex;
  padding: 0 4.38rem 0 0;
}

.index-module_logo-container__bot__BIlNF {
  height: 1.75rem;
}

.index-module_logo-container__mIYa3 {
  display: flex;
  padding: 0 2.505rem 0 0;
}

.index-module_logo-container__tolk__-ai1N {
  height: 2.25em;
}

/* ==========================================================================
   Topbar
   ========================================================================== */

.index-module_topbar__Kfatu {
  box-sizing: border-box;
  padding: var(--spacing-m) var(--spacing-xxl-3) var(--spacing-m) var(--spacing-xl);
  background-color: var(--light);
  box-shadow: inset 0 -2px 1px -1px hsl(220, 29%, 92%);
}

.index-module_topbar__containers__MIXa3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.index-module_topbar__right-container__Etgff,
.index-module_topbar__left-container__mXbf5 {
  display: flex;
  align-items: center;
}

.index-module_topbar__dropdown-brand-menu__sPVM1 {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-xxl);
}

.index-module_topbar__left-container-jsx-element__-CbMx {
  display: flex;
  margin-left: var(--spacing-xxl-2);
}

.index-module_topbar__right-container-jsx-element__sP7s2 {
  z-index: 2;
  margin-right: var(--spacing-xxl);
}

@media screen and (min-width: 768px) {
  .index-module_topbar__left-container-jsx-element__-CbMx {
    display: flex;
    margin-left: calc(var(--spacing-xxl-2) * 2);
  }
}

.index-module_upload__-xXsE {
  display: flex;
  align-items: center;
}

.index-module_upload__label__RnIKG {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 1.875rem;
  box-sizing: border-box;
  align-items: center;
  padding: var(--spacing-xxs) var(--spacing-l);
  margin-right: var(--spacing-m);
  background-color: var(--light-500);
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px var(--primary-color-500);
  color: var(--primary-color-500);
  cursor: pointer;
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.index-module_upload__icon__avCHb {
  margin-right: var(--spacing-xxs);
}

.index-module_upload__label__RnIKG:hover {
  background-color: var(--gray-300);
}

.index-module_upload__label__RnIKG:active {
  box-shadow: inset 0 0 0 1px var(--primary-color-500), inset 0 0 4px 0 var(--dark);
}

.index-module_upload__label__RnIKG:focus-visible {
  box-shadow: inset 0 0 0 2px var(--blue-500), inset 0 0 0 18px var(--primary-color-500);
}

.index-module_upload__input__4W-A4 {
  display: none;
}

.index-module_upload__preview__rqwUi {
  display: flex;
  align-items: center;
}

.index-module_upload__name__-oT2q {
  margin-right: var(--spacing-m);
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-style: italic;
}

.index-module_upload__name--filled__7zE-o {
  font-style: unset;
}

.index-module_upload__name--size-exceeded__SnsIj {
  color: var(--danger-color-500);
  font-style: italic;
}

.index-module_upload__size__ZRwkz {
  color: var(--gray-700);
  font-size: 0.75rem;
}

.index-module_upload__image__JLBS8 {
  width: 3.125rem;
  height: 3.125rem;
  border: solid 1px var(--primary-color-500);
  border-radius: 8px;
  visibility: hidden;
}

.index-module_upload__image--visible__bT39A {
  visibility: visible;
}

/* ==========================================================================
   DropdownButton
   ========================================================================== */

.index-module_dropdown-button__i6YD- {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  border: none;
  background-color: var(--light);
  border-radius: 7px;
  box-shadow: inset 0 0 0 1px var(--gray-100);
  cursor: pointer;
  outline: none;
}

.index-module_dropdown-button__i6YD-:focus-visible {
  background-color: var(--light);
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.index-module_dropdown-button__i6YD-:hover {
  background-color: var(--gray-300);
}

.index-module_dropdown-button__i6YD-:active {
  background-color: var(--gray-400);
}

.index-module_dropdown-button__label__3x4JX {
  margin: 0 var(--spacing-xxl) 0 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index-module_dropdown-button__icon-chevron-down__v-fkr {
  width: 0.875rem;
  height: 0.875rem;
  flex-shrink: 0;
  stroke: var(--gray-700);
}

.index-module_dropdown__icon-chevron-down--open__3AtrK {
  transform: rotate(180deg);
}

/* dropdown-button icon
   ========================================================================== */

.index-module_dropdown-button__icon__ACc67 {
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  margin-right: var(--spacing-xs);
}

.index-module_dropdown-button__label--icon__nNJwT {
  margin: 0 var(--spacing-xs) 0 0;
}

/* dropdown-button icon-only
   ========================================================================== */

.index-module_dropdown-button--icon-only__rNAAh {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 50%;
}

.index-module_dropdown-button__icon--icon-only__q32Jk {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0;
}

/* ==========================================================================
   Custom Dropdown
   ========================================================================== */

.index-module_dropdown__CAOjU {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.index-module_dropdown__list__yXn8O {
  position: absolute;
  z-index: 10;
  top: calc(2.375rem + 0.75rem);
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  margin: 0;
  background-color: var(--light);
  border-radius: 4px;
  box-shadow: 0 0 12px 0 hsla(0, 0%, 0%, 0.15);
}

.index-module_dropdown__list--icon-only__MZdzd {
  right: unset;
  left: unset;
  width: -webkit-max-content;
  width: max-content;
}

/* ==========================================================================
   DropdownElement
   ========================================================================== */

.index-module_dropdown-element__Ble9z {
  list-style: none;
}

.index-module_dropdown-element__button__5VlGf {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border: none;
  background-color: var(--light);
  cursor: pointer;
  outline: none;
}

.index-module_dropdown-element__button__5VlGf:hover {
  background-color: var(--gray-300);
}

.index-module_dropdown-element__button__5VlGf:focus-visible {
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.index-module_dropdown-element__icon__r5FbU {
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  margin-right: var(--spacing-xs);
  background: none;
}

.index-module_dropdown-element__icon--brand__Fll5o {
  width: unset;
  height: unset;
}

/* ==========================================================================
   DropdownElement
   ========================================================================== */

.index-module_dropdown-element__rNAEe {
  list-style: none;
}

.index-module_dropdown-element__anchor__1uUsl {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border: none;
  background-color: var(--light);
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.index-module_dropdown-element__anchor__1uUsl:hover {
  background-color: var(--gray-300);
}

.index-module_dropdown-element__anchor__1uUsl:focus-visible {
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.index-module_dropdown-element__icon__4qlSd {
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  margin-right: var(--spacing-xs);
  background: none;
}

.index-module_dropdown-element__icon--brand__uu6q4 {
  width: unset;
  height: unset;
}

/* ==========================================================================
   DropdownElement
   ========================================================================== */

.index-module_dropdown-footer__0EwaI {
  list-style: none;
}

.index-module_dropdown-footer__button__6-QAX {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem;
  border: none;
  border-top: solid 1px var(--gray-100);
  background-color: var(--light);
  cursor: pointer;
  outline: none;
}

.index-module_dropdown-footer__button__6-QAX:hover {
  background-color: var(--gray-300);
}

.index-module_dropdown-footer__button__6-QAX:focus-visible {
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.index-module_dropdown-footer__icon__rZZpt {
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  margin-right: var(--spacing-xs);
  background: none;
  stroke: var(--danger-color-500);
}

/* ==========================================================================
  DropdownSection
   ========================================================================== */

.index-module_dropdown-section__w-Ri9 {
  display: flex;
  flex-direction: column;
  background-color: var(--light);
  list-style: none;
}

.index-module_dropdown-section__list__28CgF {
  padding: 0;
}

.index-module_dropdown-section__label__t9-R9 {
  padding: 0.75rem 0.75rem 0.5rem;
  text-transform: uppercase;
}

/* ==========================================================================
   Zeplin Input
   ========================================================================== */

/* Input-label
   ========================================================================== */

.zeplin-module_input-label__sLGvm .zeplin-module_background__yIYOJ {
  padding: 0 0 0.75rem;
  background-color: var(--light);
}

.zeplin-module_input-label__sLGvm .zeplin-module_label__SEKGg {
  font-family: Rubik;
  font-size: 0.75rem;
  letter-spacing: 1.13px;
  color: var(--gray-600);
}

/* Input
   ========================================================================== */

.zeplin-module_input__iLwTi .zeplin-module_background__yIYOJ {
  border-radius: 5px;
  background-color: var(--gray-300);
  padding: 0.5rem 1rem;
  box-shadow: inset 0 0 0 1px var(--teal-200);
}

.zeplin-module_input__iLwTi .zeplin-module_label__SEKGg {
  font-family: Rubik;
  font-size: 1rem;
  line-height: 1.38;
  color: var(--gray-700);
}

.zeplin-module_input-side-infos__7EX9J {
  font-family: Rubik;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.38;
  color: var(--dark);
}

/* Input danger
   ========================================================================== */

.zeplin-module_input__iLwTi.zeplin-module_danger__dMzjO .zeplin-module_background__yIYOJ {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  box-shadow: inset 0 0 0 1px var(--danger-color-500);
}

/* ==========================================================================
   Custom Input
   ========================================================================== */

.custom-module_input-label__THAww {
  display: flex;
  flex-direction: column;
}

.custom-module_input__AIUF6 {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: var(--spacing-xxl);
}

.custom-module_input__AIUF6.custom-module_inputError__jFNcp {
  margin-bottom: 0;
}

.custom-module_input__AIUF6 .custom-module_background__hLMIN {
  width: 100%;
  border: none;
}

.custom-module_input-label__THAww > .custom-module_background__hLMIN {
  display: flex;
  background: none;
}

.custom-module_input__AIUF6 .custom-module_background__hLMIN:focus {
  box-shadow: inset 0 0 0 1px var(--purple-500), inset 0 0 0 2px var(--blue-500);
  outline: none;
}

.custom-module_input__AIUF6 .custom-module_label__jdarY {
  letter-spacing: normal;
}

.custom-module_input__AIUF6 .custom-module_label__jdarY::placeholder {
  color: var(--gray-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  letter-spacing: normal;
  line-height: 1.38;
}

.custom-module_input-label__THAww > .custom-module_background__hLMIN > .custom-module_label__jdarY {
  text-transform: uppercase;
}

.custom-module_input__AIUF6.custom-module_danger__-sXCh .custom-module_background__hLMIN,
.custom-module_input__AIUF6.custom-module_success__qP-vr .custom-module_background__hLMIN,
.custom-module_input__AIUF6.custom-module_password__nSUM4 .custom-module_background__hLMIN {
  padding: 0.5rem 2rem 0.5rem 1rem;
}

.custom-module_input__AIUF6.custom-module_danger__-sXCh .custom-module_background__hLMIN:focus {
  box-shadow: none;
  box-shadow: inset 0 0 0 1px var(--blue-500), inset 0 0 0 2px var(--danger-color-500);
  outline: none;
}

.custom-module_input__AIUF6 .custom-module_icon__tDL-4 {
  width: 1rem;
  height: 1rem;
}

.custom-module_input__AIUF6 .custom-module_icon__tDL-4.custom-module_icon-success__7kAFi,
.custom-module_input__AIUF6 .custom-module_icon__tDL-4.custom-module_icon-danger__gLVc- {
  position: absolute;
  right: var(--spacing-xs);
  display: flex;
  width: 1rem;
  height: 1rem;
  align-items: center;
}

.custom-module_input__AIUF6 .custom-module_icon-success__7kAFi {
  stroke: var(--success-color-500);
}

.custom-module_input__AIUF6 .custom-module_icon-danger__gLVc- {
  stroke: var(--danger-color-500);
}

.custom-module_input__AIUF6 .custom-module_button-icon-password__czN1c {
  position: absolute;
  right: var(--spacing-xs);
  display: flex;
  display: none;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

.custom-module_input__AIUF6.custom-module_danger__-sXCh.custom-module_password__nSUM4 .custom-module_icon-danger__gLVc-,
.custom-module_input__AIUF6.custom-module_success__qP-vr.custom-module_password__nSUM4 .custom-module_icon-success__7kAFi {
  position: absolute;
  right: var(--spacing-xxl-2);
}

.custom-module_input__AIUF6.custom-module_success__qP-vr.custom-module_password__nSUM4 .custom-module_background__hLMIN,
.custom-module_input__AIUF6.custom-module_danger__-sXCh.custom-module_password__nSUM4 .custom-module_background__hLMIN {
  padding: 0.5rem 3.5rem 0.5rem 1rem;
}

.custom-module_button-icon-password__czN1c.custom-module_is-visible__aPW-L {
  display: flex;
}

.custom-module_input-container__VWpm2 {
  display: flex;
  align-items: baseline;
}

.custom-module_input-container--error__T8GAM {
  align-items: center;
}

.custom-module_input-container--block__QiUpB {
  display: block;
}

.custom-module_input-side-infos__-8FDT {
  margin: 0;
  margin-left: var(--spacing-xs);
}

/* ==========================================================================
   Zeplin InputErrorMessage
   ========================================================================== */

.zeplin-module_input-error__AASCp .zeplin-module_background__S8994 {
  padding: 0.5rem 0;
  background-color: var(--light);
}

.zeplin-module_input-error__AASCp .zeplin-module_label__rD6ir {
  font-family: Rubik;
  font-size: 0.75rem;
  line-height: 1;
  color: var(--danger-color-500);
}

/* ==========================================================================
   Custom InputErrorMessage
   ========================================================================== */

.custom-module_input-error__UW7Ej .custom-module_background__rwpAw {
  width: 100%;
  background: none;
  overflow-wrap: anywhere;
  text-align: justify;
}

.custom-module_input-error__UW7Ej {
  display: flex;
  flex-direction: column;
}

.custom-module_input-error__UW7Ej .custom-module_label__lPSTy {
  padding-right: 0;
  font-weight: normal;
  letter-spacing: normal;
}

.custom-module_input-error__UW7Ej .custom-module_group-label__D2A4N {
  padding: 0 0 var(--spacing-xxs) 0;
}

.custom-module_input-error__UW7Ej .custom-module_group-label__D2A4N:first-child {
  padding-top: 0.5rem;
}

.custom-module_input-error__UW7Ej .custom-module_group-label__D2A4N:last-child {
  padding-bottom: 0.5rem;
}

/* ==========================================================================
   Custom Anchor
   ========================================================================== */

.index-module_anchor__hN7u4 {
  color: var(--blue-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.38;
}

.index-module_anchor__hN7u4:hover {
  color: var(--blue-300);
}

.index-module_anchor__hN7u4:active {
  color: var(--blue-700);
}

.custom-module_date-range__container__QNRWv {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--light);
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px var(--blue-100);
}

.custom-module_date-range__start__YXXap {
  margin: 0 0.5rem 0 0;
}

.custom-module_date-range__container__QNRWv input {
  width: 6.25rem;
  height: 1.375rem;
  padding: 0;
  border: none;
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.38;
  text-align: center;
}

.custom-module_date-range__icon__KcEXU {
  width: 16px;
  height: 16px;
  margin: 0 0.5rem 0 0;
  color: var(--gray-500);
}

.zeplin-module_input-error__5PjOP .zeplin-module_background__RVAqQ {
  width: 17rem;
  height: 1.75rem;
  padding: 0.5rem 0;
  background-color: var(--light);
}

.zeplin-module_input-error__5PjOP .zeplin-module_label__4yGM8 {
  width: 17rem;
  height: 0.75rem;
  font-family: Rubik;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--danger-color-500);
}


.custom-module_input-error__dqip1 .custom-module_background__zsQEb {
  width: 100%;
  background: none;
  overflow-wrap: anywhere;
  text-align: justify;
}

.custom-module_input-error__dqip1 {
  display: flex;
  margin: auto;
  overflow-x: auto;
  white-space: nowrap;
}

.custom-module_input-error__dqip1 .custom-module_label__0RBBZ {
  padding-right: 0;
  font-weight: normal;
  letter-spacing: normal;
}

.zeplin-module_input-label__iB1o8 .zeplin-module_background__K15HE {
  width: 17rem;
  height: 1.625rem;
  padding: 0 0 0.75rem;
  background-color: var(--light);
}

.zeplin-module_input-label__iB1o8 .zeplin-module_label__W0pd8 {
  width: 17rem;
  height: 0.875rem;
  font-family: Rubik;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--gray-600);
}

.zeplin-module_input-select-button__hiDEL .zeplin-module_background__K15HE {
  width: 17rem;
  height: 2.375rem;
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background-color: var(--gray-300);
  box-shadow: inset 0 0 0 1px var(--teal-200);
}

.zeplin-module_input-select-button__hiDEL.zeplin-module_danger__HXyAH .zeplin-module_background__K15HE {
  box-shadow: inset 0 0 0 1px var(--danger-color-500);
}

.zeplin-module_input-select-button__hiDEL .zeplin-module_label__W0pd8 {
  width: 14rem;
  height: 1.375rem;
  margin: 0 0.5rem 0 0;
  font-family: Rubik;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--gray-700);
}

.custom-module_input-label__mS1Or .custom-module_background__wWzff {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.custom-module_input-label__mS1Or .custom-module_label__IjQG1 {
  text-transform: uppercase;
}

.custom-module_input-select-button__-aeq3 {
  position: relative;
  margin-bottom: var(--spacing-xxl);
}

.custom-module_input-select-button__-aeq3 .custom-module_background__wWzff {
  width: 100%;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
}

.custom-module_input-select-button__-aeq3 .custom-module_label__IjQG1 {
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.38;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.custom-module_icon-container__KUIUJ {
  position: absolute;
  top: 0;
  right: var(--spacing-xs);
  display: flex;
  height: 100%;
  align-items: center;
}

.custom-module_icon-container__KUIUJ .custom-module_icon__--O0P {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  color: var(--gray-700);
}

.custom-module_icon-container__KUIUJ .custom-module_error-icon__aGt4G {
  margin-right: var(--spacing-xs);
  stroke: var(--danger-color-500);
}

.custom-module_icon-container__KUIUJ .custom-module_success-icon__XJwm5 {
  margin-right: var(--spacing-xs);
  stroke: var(--success-color-500);
}

.custom-module_input-select-button__-aeq3 .custom-module_background__wWzff:focus {
  box-shadow: inset 0 0 0 2px var(--blue-500);
}

.custom-module_input-select-button__-aeq3.custom-module_danger__TNPcg {
  margin-bottom: 0;
}

.custom-module_input-select-button__-aeq3.custom-module_danger__TNPcg .custom-module_background__wWzff:focus {
  box-shadow: inset 0 0 0 1px var(--blue-500), inset 0 0 0 2px var(--danger-color-500);
}


.index-module_toast__CNn0d {
  z-index: 9999;
  display: flex;
  min-width: 17rem;
  max-width: 24rem;
  box-sizing: border-box;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background-color: var(--gray-400);
  border-radius: 5px;
  box-shadow: 0 0 12px 0 hsla(0, 0%, 0%, 0.15), inset 0 0 0 1px var(--teal-200);
}

.index-module_toast--fixed__JhaWa {
  position: fixed;
}

.index-module_toast--bottom-left__wQZry,
.index-module_toast--bottom-right__G-U59,
.index-module_toast--bottom-center__2uCND {
  bottom: var(--spacing-xl);
  transform: translateY(calc(100% + var(--spacing-xl) + 12px));
}

.index-module_toast--top-left__k13hy,
.index-module_toast--top-right__YbRPk,
.index-module_toast--top-center__iws2Q {
  top: var(--spacing-xl);
  transform: translateY(calc(-100% - var(--spacing-xl) - 12px));
}

.index-module_toast--with-right-content__JgUDA {
  width: 100%;
  min-width: none;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.index-module_toast--visible-bottom-left__A4buB,
.index-module_toast--visible-bottom-right__6SlXY,
.index-module_toast--visible-bottom-center__tso2t {
  transform: translateY(0);
}

.index-module_toast--visible-top-left__NzZDU,
.index-module_toast--visible-top-right__r8t2B,
.index-module_toast--visible-top-center__e28nh {
  transform: translateY(0);
}

.index-module_toast--bottom-left__wQZry {
  left: var(--spacing-xl);
}

.index-module_toast--visible-bottom-center__tso2t {
  left: 50%;
  transform: translateX(-50%);
}

.index-module_toast--bottom-right__G-U59 {
  right: var(--spacing-xl);
}

.index-module_toast--top-left__k13hy {
  left: var(--spacing-xl);
}

.index-module_toast--visible-top-center__e28nh {
  left: 50%;
  transform: translateX(-50%);
}

.index-module_toast--top-right__YbRPk {
  right: var(--spacing-xl);
}

.index-module_toast__label__t3-hJ {
  margin: 0;
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375;
}

.index-module_toast__icon__wzK-d {
  display: flex;
  width: 1.375rem;
  height: 1.375rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin: 0 0.75rem 0 0;
  border-radius: 3px;
}

.index-module_toast__icon--success__fHYkm {
  background-color: var(--success-color-100);
}

.index-module_toast__icon--danger__H-RkH {
  background-color: var(--danger-color-100);
}

.index-module_toast__icon--warning__kGZoG {
  background-color: var(--warning-color-100);
}

.index-module_toast__icon--info__BjfDC {
  background-color: var(--info-color-100);
}

.index-module_toast__icon-success__eymsl {
  stroke: var(--success-color-500);
}

.index-module_toast__icon-danger__AEQGr {
  stroke: var(--danger-color-500);
}

.index-module_toast__icon-warning__LIcWO {
  stroke: var(--warning-color-500);
}

.index-module_toast__icon-info__4q7RT {
  stroke: var(--info-color-500);
}

.index-module_toast__label--with-content__-oNOL {
  margin-right: var(--spacing-s);
}

/* ==========================================================================
   Toggle
   ========================================================================== */

.index-module_toggle__OSA9e {
  position: relative;
  display: flex;
  width: 2.25rem;
  height: 1.25rem;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray-500);
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.index-module_toggle__input-container__wqOgt {
  display: flex;
  align-items: center;
  padding-right: var(--spacing-m);
}

.index-module_toggle__OSA9e input[type="checkbox"] {
  z-index: -1;
  width: var(--spacing-xxl-3);
  height: var(--spacing-l);
  margin: 0;
  opacity: 0;
}

.index-module_toggle__OSA9e:active {
  background-color: var(--gray-700);
}

.index-module_toggle__OSA9e:hover {
  background-color: var(--gray-600);
}

.index-module_toggle--disabled__XGH-Z {
  cursor: not-allowed;
}

.index-module_toggle--disabled__XGH-Z,
.index-module_toggle--disabled__XGH-Z:hover {
  background-color: var(--gray-400);
}

.index-module_toggle--checked__MX-IO {
  background-color: var(--primary-color-500);
}

.index-module_toggle--checked__MX-IO:active {
  background-color: var(--primary-color-700);
}

.index-module_toggle--checked__MX-IO:hover {
  background-color: var(--primary-color-300);
}

.index-module_toggle--checked-disabled__14Euk,
.index-module_toggle--checked-disabled__14Euk:hover {
  background-color: var(--primary-color-100);
}

.index-module_toggle__switch__wXaw2 {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  width: var(--spacing-m);
  height: var(--spacing-m);
  background-color: var(--light);
  border-radius: var(--spacing-m);
  content: '';
  transition: 0.2s;
}

input[type="checkbox"]:checked + .index-module_toggle__switch__wXaw2 {
  left: calc(100% - 0.125rem);
  transform: translateX(-100%);
}

.index-module_toggle__label__9cAui {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.38;
}

.index-module_toggle__OSA9e:focus-within input[type="checkbox"]:enabled + .index-module_toggle__switch__wXaw2 {
  box-shadow: inset 0 0 0 2px var(--light), inset 0 0 0 8px var(--primary-color-500);
}

/* ==========================================================================
   UI-Library React from Tolk Workspace
   ========================================================================== */

/* Global colors variable
   ========================================================================== */

.account-theme {
  --primary-color-100: var(--account-color-100);
  --primary-color-200: var(--account-color-200);
  --primary-color-300: var(--account-color-300);
  --primary-color-400: var(--account-color-400);
  --primary-color-500: var(--account-color-500);
  --primary-color-600: var(--account-color-600);
  --primary-color-700: var(--account-color-700);
  --primary-color-800: var(--account-color-800);
  --primary-color-900: var(--account-color-900);
  --active-border-color: var(--light);
}

.analytics-theme {
  --primary-color-100: var(--analytics-color-100);
  --primary-color-200: var(--analytics-color-200);
  --primary-color-300: var(--analytics-color-300);
  --primary-color-400: var(--analytics-color-400);
  --primary-color-500: var(--analytics-color-500);
  --primary-color-600: var(--analytics-color-600);
  --primary-color-700: var(--analytics-color-700);
  --primary-color-800: var(--analytics-color-800);
  --primary-color-900: var(--analytics-color-900);
  --active-border-color: var(--light);
}

.bot-theme {
  --primary-color-100: var(--bot-color-100);
  --primary-color-200: var(--bot-color-200);
  --primary-color-300: var(--bot-color-300);
  --primary-color-400: var(--bot-color-400);
  --primary-color-500: var(--bot-color-500);
  --primary-color-600: var(--bot-color-600);
  --primary-color-700: var(--bot-color-700);
  --primary-color-800: var(--bot-color-800);
  --primary-color-900: var(--bot-color-900);
  --active-border-color: var(--dark);
}

:root {
  --account-color-100: #d2a1f5;
  --account-color-200: #bc71f0;
  --account-color-300: #a542eb;
  --account-color-400: #9a2ae8;
  --account-color-500: #8f13e6;
  --account-color-600: #8011ce;
  --account-color-700: #720fb8;
  --account-color-800: #630da0;
  --account-color-900: #550b8a;
  --agent-color-100: #d3f0e3;
  --agent-color-200: #a7e2c7;
  --agent-color-300: #7bd3ac;
  --agent-color-400: #4fc590;
  --agent-color-500: #23b674;
  --agent-color-600: #1fa368;
  --agent-color-700: #1c915c;
  --agent-color-800: #187f50;
  --agent-color-900: #156d45;
  --analytics-color-100: #d8eaf2;
  --analytics-color-200: #b0d5e6;
  --analytics-color-300: #89bfd9;
  --analytics-color-400: #61aacd;
  --analytics-color-500: #3a95c0;
  --analytics-color-600: #3485ac;
  --analytics-color-700: #2e7799;
  --analytics-color-800: #286886;
  --analytics-color-900: #225973;
  --blue-100: #eee6ff;
  --blue-300: #3392ff;
  --blue-500: #005fcc;
  --blue-700: #003066;
  --bot-color-100: #f7d8e6;
  --bot-color-200: #efb1ce;
  --bot-color-300: #e68bb5;
  --bot-color-400: #de649d;
  --bot-color-500: #d63d84;
  --bot-color-600: #c03676;
  --bot-color-700: #ab3069;
  --bot-color-800: #952a5c;
  --bot-color-900: #80244f;
  --danger-color-100: #e1c6d0;
  --danger-color-500: #d63649;
  --dark: #2e384d;
  --gray-100: #eef3f5;
  --gray-300: #f4f6fc;
  --gray-400: #e3e6ef;
  --gray-500: #bfc5d2;
  --gray-600: #8798ad;
  --gray-700: #69707f;
  --info-color-100: #badfe9;
  --info-color-500: #00c1d4;
  --light: #fff;
  --purple-100: #e7cafb;
  --purple-500: #6102d1;
  --shopify-color-300: #26913f;
  --shopify-color-500: #009221;
  --shopify-color-700: #006818;
  --success-color-100: #c3dbcc;
  --success-color-500: #33ac2e;
  --teal-200: #e0e7ff;
  --teal-500: #00c1d4;
  --warning-color-100: #e8cac3;
  --warning-color-500: #ff4c00;
  --yellow-500: #f7c137;
  --chart-color-1: #5493bc;
  --chart-color-2: #2f454a;
  --chart-color-3: #80b583;
  --chart-color-4: #ece41b;
  --chart-color-5: #e28540;
  --chart-color-6: #e46da7;
  --chart-color-7: #a100bb;
  --spacing-xxs: 0.25rem;
  --spacing-xs: 0.5rem;
  --spacing-s: 0.75rem;
  --spacing-m: 1rem;
  --spacing-l: 1.25rem;
  --spacing-xl: 1.5rem;
  --spacing-xxl: 1.75rem;
  --spacing-xxl-2: 2rem;
  --spacing-xxl-3: 2.25rem;
}

/* Global font files
   ========================================================================== */

@font-face {
  font-family: Rubik;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src:
    local(rubik-light),
    url(./assets/fonts/Rubik-Light.woff2) format('woff2'),
    url(./assets/fonts/Rubik-Light.woff) format('woff'),
    url(./assets/fonts/Rubik-Light.ttf) format('truetype');
}

@font-face {
  font-family: Rubik;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src:
    local(rubik-regular),
    url(./assets/fonts/Rubik-Regular.woff2) format('woff2'),
    url(./assets/fonts/Rubik-Regular.woff) format('woff'),
    url(./assets/fonts/Rubik-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Rubik;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src:
    local(rubik-medium),
    url(./assets/fonts/Rubik-Medium.woff2) format('woff2'),
    url(./assets/fonts/Rubik-Medium.woff) format('woff'),
    url(./assets/fonts/Rubik-Medium.ttf) format('truetype');
}

/* Globale styles
   ========================================================================== */

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.sr-only {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  margin: -1px !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
          clip-path: inset(50%) !important;
  white-space: nowrap !important;
}

.hidden {
  display: none;
}

.text-regular-5xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 2.875rem;
}

.text-bold-4xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 0.8;
}

.text-regular-4xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 2.5rem;
  line-height: 0.8;
}

.text-regular-3xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 2.125rem;
  line-height: 0.94;
}

.text-bold-3xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 2.125rem;
  font-weight: bold;
  line-height: 0.94;
}

.text-regular-2xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1.75rem;
  line-height: 1.14;
}

.text-bold-2xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.14;
}

.text-bold-xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.45;
}

.text-regular-xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1.375rem;
}

.text-light-xl-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1.375rem;
  font-weight: 300;
}

.text-regular-lg-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1.125rem;
}

.text-bold-base-light {
  color: var(--light);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.375;
}

.text-medium-base-light {
  color: var(--light);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375;
}

.text-medium-base-light-500--underline {
  color: var(--light);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375;
  text-decoration: underline;
}

.text-medium-base-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375;
}

.text-medium-base-bot-500 {
  color: var(--brand-bot-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375;
}

.text-medium-base-gray-700 {
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375;
}

.text-regular-base-brand-500 {
  color: var(--brand-main-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.text-regular-base-blue-500 {
  color: var(--blue-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.label-regular-base-gray-600 {
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  letter-spacing: 1.5px;
  line-height: 1.375;
}

.label-regular-base-gray-500 {
  color: var(--gray-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  letter-spacing: 1.5px;
  line-height: 1.375;
}

.text-regular-base-gray-500 {
  color: var(--gray-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.text-regular-base-gray-600 {
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.text-regular-base-bot-500 {
  color: var(--brand-bot-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.text-regular-base-danger-500 {
  color: var(--danger-color-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.text-regular-base-light {
  color: var(--light);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.text-regular-base-gray-700 {
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.text-regular-base-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

.text-italic-base-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-style: italic;
  line-height: 1.375;
}

.text-regular-base-dark--center {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.38;
  text-align: center;
}

.text-light-base-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375;
}

.text-medium-sm-gray-600 {
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.83;
}

.text-regular-sm-gray-600 {
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  line-height: normal;
}

.text-regular-sm-danger-500 {
  color: var(--danger-color-500);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
}

.text-regular-sm-gray-700 {
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
}

.label-regular-sm-gray-500 {
  color: var(--gray-500);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1px;
}

.label-regular-sm-gray-700 {
  color: var(--gray-700);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1px;
}

.label-regular-sm-gray-600 {
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1px;
}

.label-regular-sm-gray-600-justify {
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1px;
  text-align: justify;
}

.label-regular-sm-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1px;
}

.text-regular-sm-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
}

.text-light-sm-gray-600 {
  color: var(--gray-600);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
}

.text-light-sm-dark {
  color: var(--dark);
  font-family: Rubik, sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
}

.text-regular-base-blue-500--underline {
  color: var(--blue-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.38;
}

.text-regular-base-primary-500 {
  color: var(--primary-color-500);
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  line-height: 1.375;
}

/* Date Range Picker from Material Ui
   ========================================================================== */

.css-1ozefeb {
  margin: 1rem 0 0 0 !important;
}

.css-1ozefeb span {
  font-family: Rubik, sans-serif;
}

.css-1ozefeb .Mui-selected {
  background-color: var(--primary-color-500) !important;
}

.css-1ozefeb .MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: var(--primary-color-200) !important;
}

/* Data Grid from Material Ui
   ========================================================================== */

.MuiDataGrid-panel label,
.MuiDataGrid-panel select,
.MuiDataGrid-panel input,
.MuiDataGrid-panel button,
.MuiDataGrid-menu ul li {
  font-family: Rubik, sans-serif;
}

.MuiDataGrid-columnHeaders {
  z-index: 10;
}

.MuiDataGrid-root {
  color: var(--dark) !important;
}
